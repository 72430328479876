html {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, :before, :after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

abbr, address, article, aside, audio, b, blockquote, body, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figure, footer, form, h1, h2, h3, h4, h5, h6, header, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  vertical-align: baseline;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: 400;
}

img {
  max-width: 100%;
}

a {
  vertical-align: baseline;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

span {
  font-weight: inherit;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

strong {
  font-weight: bold;
}

label, button {
  cursor: pointer;
}

input[type="button"] {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

input[type="file"] {
  cursor: pointer;
}

button, input, select, textarea {
  margin: 0;
}

button {
  width: auto;
  overflow: visible;
}

input[type="button"] {
  width: auto;
  overflow: visible;
}

:root {
  --ioc_line_height_1: 40px;
  --ioc_line_height_0: 32px;
  --ioc_line_height_-1: 24px;
  --ioc_line_height: 1.4;
  --ioc_light_theme_shadow: 0 1px 3px 0 rgba(0, 0, 0, .22);
  --ioc_dark_theme_shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);
  --ioc_opacity_disabled: .6;
  --ioc_focus_box_shadow: 0 0 0 1px rgba(118, 149, 249, .63), 0 0 0 4px rgba(111, 143, 246, .5);
  --ioc_font_family_sans_serif: "Soleil", Helvetica, Arial, sans-serif;
  --ioc_font_weight_bold: 600;
  --ioc_font_size_2: 20px;
  --ioc_font_size_1: 16px;
  --ioc_font_size_0: 13px;
  --ioc_font_size_-1: 11px;
  --ioc_light_theme_grey_0_hsl: 214, 0%, 100%;
  --ioc_light_theme_grey_1_hsl: 214, 0%, 98%;
  --ioc_light_theme_grey_2_hsl: 214, 6%, 94%;
  --ioc_light_theme_grey_3_hsl: 214, 5%, 84%;
  --ioc_light_theme_grey_4_hsl: 214, 6%, 75%;
  --ioc_light_theme_grey_5_hsl: 214, 7%, 54%;
  --ioc_light_theme_grey_6_hsl: 214, 15%, 24%;
  --ioc_dark_theme_grey_0_hsl: 214, 12%, 11%;
  --ioc_dark_theme_grey_1_hsl: 214, 12%, 13%;
  --ioc_dark_theme_grey_2_hsl: 214, 12%, 16%;
  --ioc_dark_theme_grey_3_hsl: 218, 5%, 31%;
  --ioc_dark_theme_grey_4_hsl: 214, 11%, 38%;
  --ioc_dark_theme_grey_5_hsl: 214, 11%, 75%;
  --ioc_dark_theme_grey_6_hsl: 0, 0%, 90%;
  --ioc_light_theme_grey_0: hsl(var(--ioc_light_theme_grey_0_hsl));
  --ioc_light_theme_grey_1: hsl(var(--ioc_light_theme_grey_1_hsl));
  --ioc_light_theme_grey_2: hsl(var(--ioc_light_theme_grey_2_hsl));
  --ioc_light_theme_grey_3: hsl(var(--ioc_light_theme_grey_3_hsl));
  --ioc_light_theme_grey_4: hsl(var(--ioc_light_theme_grey_4_hsl));
  --ioc_light_theme_grey_5: hsl(var(--ioc_light_theme_grey_5_hsl));
  --ioc_light_theme_grey_6: hsl(var(--ioc_light_theme_grey_6_hsl));
  --ioc_dark_theme_grey_0: hsl(var(--ioc_dark_theme_grey_0_hsl));
  --ioc_dark_theme_grey_1: hsl(var(--ioc_dark_theme_grey_1_hsl));
  --ioc_dark_theme_grey_2: hsl(var(--ioc_dark_theme_grey_2_hsl));
  --ioc_dark_theme_grey_3: hsl(var(--ioc_dark_theme_grey_3_hsl));
  --ioc_dark_theme_grey_4: hsl(var(--ioc_dark_theme_grey_4_hsl));
  --ioc_dark_theme_grey_5: hsl(var(--ioc_dark_theme_grey_5_hsl));
  --ioc_dark_theme_grey_6: hsl(var(--ioc_dark_theme_grey_6_hsl));
  --ioc_blue_1: #87a1f7;
  --ioc_blue_values: 226, 88%, 65%;
  --ioc_blue: hsl(var(--ioc_blue_values));
  --ioc_blue_-1: #2756f1;
  --ioc_blue_-2: #042cae;
  --ioc_bright_blue: #668aff;
  --ioc_navy_0: #5466b0;
  --ioc_navy_-1: #283848;
  --ioc_red_values: 346, 100%, 69%;
  --ioc_red: hsl(var(--ioc_red_values));
  --ioc_red_-1: #f53864;
  --ioc_green: #3ce29f;
  --ioc_orange: #e77e23;
  --ioc_yellow: #ffd058;
  --ioc_yellow_-1: #cf9b17;
  --ioc_purple: #9681f4;
  --ioc_green: #00e384;
  --ioc_pink: #ff8fad;
  --ioc_severity_danger: var(--ioc_red);
  --ioc_severity_warning: var(--ioc_yellow);
  --ioc_severity_info: var(--ioc_blue);
  --ioc_severity_success: var(--ioc_green);
  --ioc_severity_neutral: var(--ioc_dark_theme_grey_4);
  --ioc_easing_magnetic: cubic-bezier(1, 0, .7, 1);
}

@-webkit-keyframes IO-Components__Loader__spin-around--2bPP8 {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes IO-Components__Loader__spin-around--2bPP8 {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.IO-Components__Loader__base--1aKjW {
  --primary-color-white: var(--ioc_light_theme_grey_0);
  --secondary-color-white: rgba(255, 255, 255, .3);
  --primary-color-dark: var(--ioc_dark_theme_grey_0);
  --secondary-color-dark: rgba(0, 0, 0, .3);
  --primary-color-red: var(--ioc_red);
  --secondary-color-red: hsla(var(--ioc_red_values), .3);
  --primary-color: var(--primary-color-white);
  --secondary-color: var(--secondary-color-white);
  opacity: .8;
  border: 2px solid var(--primary-color);
  border-right-color: var(--secondary-color);
  border-top-color: var(--secondary-color);
  content: "";
  font-family: var(--ioc_font_family_sans_serif);
  border-radius: 100%;
  -webkit-animation: .45s linear infinite IO-Components__Loader__spin-around--2bPP8;
  animation: .45s linear infinite IO-Components__Loader__spin-around--2bPP8;
  display: block;
  position: relative;
}

.IO-Components__Loader__base--color-red--2RNu6 {
  --primary-color: var(--primary-color-red);
  --secondary-color: var(--secondary-color-red);
}

.IO-Components__Loader__base--color-black--2WPvz {
  --primary-color: var(--primary-color-dark);
  --secondary-color: var(--secondary-color-dark);
}

.IO-Components__Loader__base--size-small--2umrH {
  width: 12px;
  height: 12px;
}

.IO-Components__Loader__base--size-medium--1n6WY {
  width: 16px;
  height: 16px;
}

.IO-Components__Loader__base--size-large--3v8pa {
  width: 20px;
  height: 20px;
}

.IO-Components___Button__base--DKdgh {
  --icon-margin: .5em;
  height: var(--height);
  -o-transition: all .12s ease;
  font-family: var(--ioc_font_family_sans_serif);
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
  line-height: var(--ioc_line_height);
  --bg-color-primary: var(--ioc_blue);
  --bg-color-primary-dangerous: var(--ioc_red);
  --bg-color: var(--bg-color-primary);
  --bg-hover-color-primary: var(--ioc_blue_-1);
  --text_color_primary: var(--ioc_light_theme_grey_0);
  --bg-color-secondary-light: var(--ioc_light_theme_grey_0);
  --bg-color-secondary-dark: var(--ioc_dark_theme_grey_3);
  --bg-color-secondary: var(--bg-color-secondary-light);
  --bg-hover-color-secondary: var(--ioc_light_theme_grey_2);
  --text-color-secondary-light: var(--ioc_light_theme_grey_6);
  --text-color-secondary-dark: var(--ioc_dark_theme_grey_6);
  --text-color-secondary-dangerous: var(--ioc_red);
  --text-color-secondary: var(--text-color-secondary-light);
  --bg-hover-color: var(--bg-hover-color-primary);
  --size-small: var(--ioc_line_height_-1);
  --size-medium: var(--ioc_line_height_0);
  --size-large: var(--ioc_line_height_1);
  border: 0;
  border-radius: 3px;
  max-height: 100%;
  padding: 0 9px;
  transition: all .12s;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
}

.IO-Components___Button__base--DKdgh ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.IO-Components___Button__base--DKdgh:focus {
  z-index: 1;
  -webkit-box-shadow: var(--ioc_focus_box_shadow);
  box-shadow: var(--ioc_focus_box_shadow);
  outline: 0;
}

.IO-Components___Button__base--DKdgh:active {
  outline: 0;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.IO-Components___Button__base--theme-dark--2pyMq {
  --bg-color-secondary: var(--bg-color-secondary-dark);
  --text-color-secondary: var(--text-color-secondary-dark);
  --bg-hover-color-secondary: var(--ioc_dark_theme_grey_1);
}

.IO-Components___Button__base--dangerous--1DaHV {
  --text-color-secondary: var(--text-color-secondary-dangerous);
  --bg-color-primary: var(--bg-color-primary-dangerous);
  --background-color-primary: var(--bg-color-dangerous);
  --bg-hover-color-primary: var(--ioc_red_-1);
}

.IO-Components___Button__base--no-label--3oZZk {
  --icon-margin: 0;
  width: var(--height);
}

.IO-Components___Button__base--state-disabled--3j2z3 {
  pointer-events: none;
  opacity: var(--ioc_opacity_disabled);
}

.IO-Components___Button__base--width-flex--1qM6L {
  width: 100%;
}

.IO-Components___Button__base--size-large--2GcRe {
  font-size: var(--ioc_font_size_1);
  --height: var(--size-large);
}

.IO-Components___Button__base--size-medium--2egrf {
  font-size: var(--ioc_font_size_0);
  --height: var(--size-medium);
}

.IO-Components___Button__base--size-small--1Ul-C {
  font-size: var(--ioc_font_size_-1);
  --height: var(--size-small);
}

.IO-Components___Button__base--priority-primary--36Gnr {
  background-color: var(--bg-color);
  color: var(--text_color_primary);
}

.IO-Components___Button__base--priority-primary--36Gnr:hover {
  background-color: var(--bg-hover-color-primary);
}

.IO-Components___Button__base--priority-secondary--1JpVb {
  background-color: var(--bg-color-secondary);
  color: var(--text-color-secondary);
}

.IO-Components___Button__base--priority-secondary--1JpVb:hover {
  background-color: var(--bg-hover-color-secondary);
}

.IO-Components___Button__content-wrapper--3tS1K {
  justify-content: center;
  align-items: center;
  display: flex;
}

.IO-Components___Button__icon-wrapper--7b_19 {
  margin-right: var(--icon-margin);
  align-items: center;
  margin-top: 1px;
  display: flex;
}

.IO-Components___Button__right-icon-wrapper--1oyLH {
  margin-left: var(--icon-margin);
  align-items: center;
  margin-top: 1px;
  display: flex;
}

.IO-Components___Button__loading-label--nWBs8 {
  font-style: italic;
}

.IO-Components___Button__base--position-first--2ZI_d {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
}

.IO-Components___Button__base--position-first--2ZI_d.IO-Components___Button__base--group-direction-vertical--1H0Sn {
  border-radius: 3px 3px 0 0;
  margin-bottom: 1px;
  margin-right: 0;
}

.IO-Components___Button__base--position-middle--O11rT {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  margin-right: 1px;
}

.IO-Components___Button__base--position-middle--O11rT.IO-Components___Button__base--group-direction-vertical--1H0Sn {
  margin-bottom: 1px;
  margin-right: 0;
}

.IO-Components___Button__base--position-last--1p-Wr {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.IO-Components___Button__base--position-last--1p-Wr.IO-Components___Button__base--group-direction-vertical--1H0Sn {
  border-radius: 0 0 3px 3px;
}

.IO-Components__Icon__base--2w82Q {
  font-size: var(--ioc_font_size_0);
  color: inherit;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.IO-Components__ButtonGroupWrapper__base--1KzfC {
  background-color: rgba(0, 0, 0, .2);
  border-radius: 3px;
  flex-shrink: 0;
  display: inline-flex;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
}

.IO-Components__ButtonGroup__base--direction-vertical--2UJTW {
  flex-direction: column;
}

.IO-Components___Tooltip__variables--2LudE {
  --background-color-light: var(--ioc_light_theme_grey_1);
  --background-color-dark: var(--ioc_dark_theme_grey_2);
  --background-color-menu: var(--ioc_navy_-1);
  --background-color: var(--background-color-light);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --arrow-size-small: 8px;
  --arrow-size-medium: 10px;
  --arrow-size-large: 12px;
  --arrow-size: var(--arrow-size-medium);
  --padding-small: 8px;
  --padding-medium: 12px;
  --padding-large: 18px;
  --padding: var(--padding-medium);
  --outline-shadow-light: drop-shadow(0 0 1px rgba(0, 0, 0, .2));
  --outline-shadow-dark: drop-shadow(0 0 1px rgba(0, 0, 0, .6));
  --outline-shadow: var(--outline-shadow-light);
  --border-radius-small: 2px;
  --border-radius-medium: 4px;
  --border-radius-large: 6px;
  --border-radius: var(--border-radius-medium);
}

.IO-Components___Tooltip__base--11Lax {
  opacity: 1;
  -webkit-filter: var(--outline-shadow) drop-shadow(0 9px 10px rgba(0, 0, 0, .1));
  filter: var(--outline-shadow) drop-shadow(0 9px 10px rgba(0, 0, 0, .1));
  padding: var(--padding);
  color: var(--text-color);
  font-family: var(--ioc_font_family_sans_serif);
  position: relative;
}

.IO-Components___Tooltip__base--theme-dark--2TkrQ {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --outline-shadow: var(--outline-shadow-dark);
}

.IO-Components___Tooltip__base--theme-header--kdp1f {
  --background-color: var(--background-color-menu);
  --text-color: var(--text-color-dark);
  --outline-shadow: var(--outline-shadow-dark);
}

.IO-Components___Tooltip__base--size-small--2Qxt0 {
  font-size: var(--ioc_font_size_-1);
  --arrow-size: var(--arrow-size-small);
  --padding: var(--padding-small);
  --border-radius: var(--border-radius-small);
}

.IO-Components___Tooltip__base--size-medium--2jDGD {
  font-size: var(--ioc_font_size_0);
  --arrow-size: var(--arrow-size-medium);
  --padding: var(--padding-medium);
  --border-radius: var(--border-radius-medium);
}

.IO-Components___Tooltip__base--size-large--23IOp {
  font-size: var(--ioc_font_size_1);
  --arrow-size: var(--arrow-size-large);
  --padding: var(--padding-large);
  --border-radius: var(--border-radius-large);
}

.IO-Components___Tooltip__container--2GFd3 {
  position: relative;
}

.IO-Components___Tooltip__content--3GwUk {
  padding: var(--padding);
  background-color: var(--background-color);
  border-radius: 3px;
  width: auto;
  max-width: 600px;
  position: relative;
  overflow: hidden;
}

.IO-Components___Tooltip__base--no-padding--xY5BF .IO-Components___Tooltip__content--3GwUk {
  padding: 0;
}

.IO-Components___Tooltip__arrow--3FhcC {
  width: var(--arrow-size);
  background-color: var(--background-color);
  border-bottom-right-radius: var(--border-radius);
  z-index: 0;
  position: absolute;
}

.IO-Components___Tooltip__arrow--position-top--HnJ48 {
  height: var(--arrow-size);
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%)rotate(45deg);
  transform: translate(-50%, -50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--position-bottom--1otRj {
  height: var(--arrow-size);
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%)rotate(45deg);
  transform: translate(-50%, 50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--position-left--2lO0f {
  height: var(--arrow-size);
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-50%, -50%)rotate(45deg);
  transform: translate(-50%, -50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--position-right--1DHdt {
  height: var(--arrow-size);
  position: absolute;
  top: 50%;
  right: 100%;
  -webkit-transform: translate(50%, -50%)rotate(45deg);
  transform: translate(50%, -50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-start--88bTM.IO-Components___Tooltip__arrow--position-top--HnJ48 {
  left: 8px;
  -webkit-transform: translate(0, -50%)rotate(45deg);
  transform: translate(0, -50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-start--88bTM.IO-Components___Tooltip__arrow--position-bottom--1otRj {
  left: 8px;
  -webkit-transform: translate(0, 50%)rotate(45deg);
  transform: translate(0, 50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-start--88bTM.IO-Components___Tooltip__arrow--position-left--2lO0f {
  top: 8px;
  -webkit-transform: translate(-50%)rotate(45deg);
  transform: translate(-50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-start--88bTM.IO-Components___Tooltip__arrow--position-right--1DHdt {
  top: 8px;
  -webkit-transform: translate(50%)rotate(45deg);
  transform: translate(50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-end--1bFCg.IO-Components___Tooltip__arrow--position-top--HnJ48 {
  left: initial;
  right: 8px;
  -webkit-transform: translate(0, -50%)rotate(45deg);
  transform: translate(0, -50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-end--1bFCg.IO-Components___Tooltip__arrow--position-bottom--1otRj {
  left: initial;
  right: 8px;
  -webkit-transform: translate(0, 50%)rotate(45deg);
  transform: translate(0, 50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-end--1bFCg.IO-Components___Tooltip__arrow--position-left--2lO0f {
  top: initial;
  bottom: 8px;
  -webkit-transform: translate(-50%)rotate(45deg);
  transform: translate(-50%)rotate(45deg);
}

.IO-Components___Tooltip__arrow--alignment-end--1bFCg.IO-Components___Tooltip__arrow--position-right--1DHdt {
  top: initial;
  bottom: 8px;
  -webkit-transform: translate(50%)rotate(45deg);
  transform: translate(50%)rotate(45deg);
}

.IO-Components__withTooltip__variables--1pCuE {
  --transform-top: translateY(10px);
  --transform-bottom: translateY(-10px);
  --transform-left: translateX(10px);
  --transform-right: translateX(-10px);
  --transform: var(--transform-top);
}

.IO-Components__withTooltip__base--1hg_j {
  z-index: 1;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.IO-Components__withTooltip__base--display-inline-block--11Mt8 {
  display: inline-block;
}

.IO-Components__withTooltip__base--display-block--1LZC6 {
  display: block;
}

.IO-Components__withTooltip__base--position-top--3pmdh {
  --transform: var(--transform-top);
}

.IO-Components__withTooltip__base--position-left--1pZFz {
  --transform: var(--transform-left);
}

.IO-Components__withTooltip__base--position-right--148k1 {
  --transform: var(--transform-right);
}

.IO-Components__withTooltip__base--position-bottom--3bht2 {
  --transform: var(--transform-bottom);
}

.IO-Components__withTooltip__base--is-active--1Ab44 {
  z-index: 2;
}

.IO-Components__withTooltip__tooltip-container--2_JGd {
  z-index: 10;
  visibility: hidden;
  position: fixed;
}

.IO-Components__withTooltip__base--hover-controlled--RwBmo:hover .IO-Components__withTooltip__tooltip-container--2_JGd, .IO-Components__withTooltip__base--is-active--1Ab44 .IO-Components__withTooltip__tooltip-container--2_JGd {
  visibility: initial;
}

.IO-Components__withTooltip__tooltip--1kOaQ {
  opacity: 0;
  -webkit-transform: var(--transform);
  -ms-transform: var(--transform);
  -webkit-transform: var(--transform);
  transform: var(--transform);
  -o-transition: all .15s ease;
  transition: all .15s;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-property: -webkit-transform opacity;
  transition-property: -webkit-transform opacity;
  -o-transition-property: transform opacity;
  transition-property: transform opacity;
  transition-property: transform opacity, -webkit-transform opacity;
}

.IO-Components__withTooltip__base--hover-controlled--RwBmo:hover .IO-Components__withTooltip__tooltip--1kOaQ, .IO-Components__withTooltip__base--is-active--1Ab44 .IO-Components__withTooltip__tooltip--1kOaQ {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.IO-Components__withTooltip__tooltip-container--position-top--2rp9G {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.IO-Components__withTooltip__tooltip-container--position-bottom--2iSTM {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.IO-Components__withTooltip__tooltip-container--position-left--3_leG {
  top: 50%;
  left: auto;
  right: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.IO-Components__withTooltip__tooltip-container--position-right--YDGIr {
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.IO-Components__withTooltip__tooltip-container--arrow-alignment-start--1dzuD.IO-Components__withTooltip__tooltip-container--position-top--2rp9G, .IO-Components__withTooltip__tooltip-container--arrow-alignment-start--1dzuD.IO-Components__withTooltip__tooltip-container--position-bottom--2iSTM {
  left: -12px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.IO-Components__withTooltip__tooltip-container--arrow-alignment-start--1dzuD.IO-Components__withTooltip__tooltip-container--position-left--3_leG, .IO-Components__withTooltip__tooltip-container--arrow-alignment-start--1dzuD.IO-Components__withTooltip__tooltip-container--position-right--YDGIr {
  top: -12px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.IO-Components__withTooltip__tooltip-container--arrow-alignment-end--30xFw.IO-Components__withTooltip__tooltip-container--position-top--2rp9G, .IO-Components__withTooltip__tooltip-container--arrow-alignment-end--30xFw.IO-Components__withTooltip__tooltip-container--position-bottom--2iSTM {
  left: initial;
  right: -12px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.IO-Components__withTooltip__tooltip-container--arrow-alignment-end--30xFw.IO-Components__withTooltip__tooltip-container--position-left--3_leG, .IO-Components__withTooltip__tooltip-container--arrow-alignment-end--30xFw.IO-Components__withTooltip__tooltip-container--position-right--YDGIr {
  top: initial;
  bottom: -12px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.IO-Components__Text__base--1a06M {
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  font-family: var(--ioc_font_family_sans_serif);
  color: var(--text-color);
  font-size: var(--ioc_font_size_0);
}

.IO-Components__Text__base--theme-dark--2jr--, .IO-Components__Text__base--theme-header--nfZe_ {
  color: var(--text-color-dark);
}

.IO-Components__Text__base--size-small--2-eB5 {
  font-size: var(--ioc_font_size_-1);
}

.IO-Components__Text__base--size-large--1rBDP {
  font-size: var(--ioc_font_size_1);
}

.IO-Components__Text__base--priority-secondary--2qO2V {
  --text-color-light: var(--ioc_light_theme_grey_5);
  --text-color-dark: var(--ioc_dark_theme_grey_5);
}

.IO-Components__Menu__base--CqqrP {
  --border-color-light: var(--ioc_light_theme_grey_3);
  --border-color-dark: var(--ioc_dark_theme_grey_4);
  --border-color: var(--border-color-light);
  --menu-item-padding-small: 8px;
  --menu-item-padding-medium: 12px;
  --menu-item-padding-large: 18px;
  --menu-item-padding: var(--menu-item-padding-medium);
  --menu-item-hover-background-light: var(--ioc_light_theme_grey_3);
  --menu-item-hover-background-dark: var(--ioc_dark_theme_grey_4);
  --menu-item-hover-background: var(--menu-item-hover-background-light);
  --text-highlight-color-light: var(--ioc_light_theme_grey_5);
  --text-highlight-color-dark: var(--ioc_dark_theme_grey_5);
  --text-highlight-color: var(--text-highlight-color-light);
  font-family: var(--ioc_font_family_sans_serif);
}

.IO-Components__Menu__base--theme-dark--2tUN7 {
  --border-color: var(--border-color-dark);
  --menu-item-hover-background: var(--menu-item-hover-background-dark);
  --text-highlight-color: var(--text-highlight-color-dark);
}

.IO-Components__Menu__base--size-small--1I8qr {
  --menu-item-padding: var(--menu-item-padding-small);
}

.IO-Components__Menu__base--size-large--3ogFM {
  --menu-item-padding: var(--menu-item-padding-large);
}

.IO-Components__Menu__base--state-disabled--m6ChZ {
  opacity: var(--ioc_opacity_disabled);
  pointer-events: none;
}

.IO-Components__Menu__menu--2Z7m8 {
  overflow: auto;
}

.IO-Components__Menu__menu--2Z7m8::-webkit-scrollbar {
  display: none;
}

.IO-Components__Menu__menu-overflow--3WgKe {
  overflow: auto;
}

.IO-Components__Menu__item--2NVhe {
  white-space: nowrap;
  padding: var(--menu-item-padding);
  cursor: pointer;
  font-size: var(--font-size);
  line-height: var(--ioc_line_height);
}

.IO-Components__Menu__item--2NVhe:hover {
  background: var(--menu-item-hover-background);
}

.IO-Components__Menu__trigger--3C4zx {
  cursor: pointer;
  align-items: center;
  font-weight: bold;
  display: flex;
}

.IO-Components__Menu__trigger--3C4zx:hover {
  color: var(--text-highlight-color);
}

.IO-Components__Menu__icon--KnYMr {
  align-items: center;
  margin-top: 2px;
  padding-left: 5px;
  display: flex;
}

.IO-Components__Menu__icon--KnYMr i {
  color: inherit;
}

.IO-Components__Link__base--1cUgS {
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --text-highlight-color-light: var(--ioc_light_theme_grey_5);
  --text-highlight-color-dark: var(--ioc_dark_theme_grey_5);
  --text-highlight-color: var(--text-highlight-color-light);
  color: var(--text-color);
  cursor: pointer;
  font-weight: 600;
  font-family: var(--ioc_font_family_sans_serif);
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.IO-Components__Link__base--1cUgS:hover {
  color: var(--text-highlight-color);
}

.IO-Components__Link__base--theme-dark--z8tZL {
  --text-highlight-color: var(--text-highlight-color-dark);
  --text-color: var(--text-color-dark);
}

.IO-Components__Link__base--size-small--22QjO {
  font-size: var(--ioc_font_size_-1);
}

.IO-Components__Link__base--size-medium--LPJhm {
  font-size: var(--ioc_font_size_0);
}

.IO-Components__Link__base--size--large--3LQL6 {
  font-size: var(--ioc_font_size_1);
}

.IO-Components__Link__arrow--1kvmE {
  align-items: center;
  margin-left: 4px;
  display: flex;
  -webkit-transform: scale(.8);
  transform: scale(.8);
}

.IO-Components__Link__arrow--size-small--2mZM- {
  font-size: 7px;
}

.IO-Components__Link__arrow--size-medium--3b0iy {
  font-size: 9px;
}

.IO-Components__Link__arrow--size-large--2UfqO {
  font-size: 12px;
}

.IO-Components__Link__link-element--35D8u {
  all: inherit;
}

.IO-Components__Link__label--1izm7 {
  align-items: center;
  display: flex;
}

.IO-Components__Link__icon--3Dy1H {
  align-items: center;
  margin-right: 5px;
  font-size: 11px;
  display: flex;
}

.IO-Components__Select__base--2Qg-S {
  cursor: pointer;
  background: var(--ioc_light_theme_grey_0);
  -webkit-box-shadow: var(--ioc_light_theme_shadow);
  box-shadow: var(--ioc_light_theme_shadow);
  color: var(--ioc_light_theme_grey_6);
  font-family: var(--ioc_font_family_sans_serif);
  border-radius: 3px;
  align-items: center;
  display: inline-block;
  position: relative;
}

.IO-Components__Select__base--state-disabled--1QoBh {
  pointer-events: none;
  opacity: var(--ioc_opacity_disabled);
}

.IO-Components__Select__base--theme-dark--5jy8E {
  -webkit-box-shadow: var(--ioc_dark_theme_shadow);
  box-shadow: var(--ioc_dark_theme_shadow);
  background-color: var(--ioc_dark_theme_grey_3);
}

.IO-Components__Select__base--width-flex--3b42h {
  width: 100%;
}

.IO-Components__Select__base--size-large--rxmJo {
  font-size: var(--ioc_font_size_1);
  height: var(--ioc_line_height_1);
}

.IO-Components__Select__base--size-medium--1Wrub {
  font-size: var(--ioc_font_size_0);
  height: var(--ioc_line_height_0);
}

.IO-Components__Select__base--size-small--34pK7 {
  font-size: var(--ioc_font_size_-1);
  height: var(--ioc_line_height_-1);
}

.IO-Components__Select__native-select--3xPxU {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: var(--ioc_font_family_sans_serif);
  font-weight: 400;
  font-size: inherit;
  color: inherit;
  -o-transition: all .12s ease;
  line-height: var(--ioc_line_height);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  width: 100%;
  height: 100%;
  padding: 0 40px 0 10px;
  transition: all .12s;
  display: block;
}

.IO-Components__Select__native-select--3xPxU:active {
  outline: 0;
}

.IO-Components__Select__native-select--3xPxU:focus {
  -webkit-box-shadow: var(--ioc_focus_box_shadow);
  box-shadow: var(--ioc_focus_box_shadow);
  outline: 0;
}

.IO-Components__Select__native-select--theme-dark--1khbC {
  color: var(--ioc_dark_theme_grey_6);
}

.IO-Components__Select__arrow--2SvDm {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
}

.IO-Components__Select__arrow--theme-dark--3704F {
  color: #9a9a98;
}

.IO-Components__OptionsList__options--2CI_i {
  --hightlight-color: var(--ioc_light_theme_grey_2);
  z-index: 10;
  border: 1px solid var(--ioc_light_theme_grey_2);
  background-color: var(--ioc_light_theme_grey_0);
  font-family: var(--ioc_font_family_sans_serif);
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}

.IO-Components__OptionsList__options--theme-dark--3QQEe {
  --hightlight-color: var(--ioc_dark_theme_grey_3);
  background-color: var(--ioc_dark_theme_grey_1);
  color: var(--ioc_dark_theme_grey_6);
  border: 1px solid var(--ioc_dark_theme_grey_3);
}

.IO-Components__OptionsList__option--As4zU {
  cursor: pointer;
  padding: 5px 9px;
}

.IO-Components__OptionsList__option--As4zU:hover, .IO-Components__OptionsList__option--is-active--31LsJ {
  background-color: var(--hightlight-color);
}

.IO-Components__OptionsList__options--size-small--us9Gq {
  font-size: var(--io-components_font_size_-1);
}

.IO-Components__OptionsList__options--size-large--2PS8j {
  font-size: var(--io-components_font_size_1);
}

.IO-Components___Input__base--1xwxu {
  background-color: var(--ioc_light_theme_grey_1);
  border: 1px solid var(--ioc_light_theme_grey_3);
  height: var(--ioc_line_height_0);
  color: var(--ioc_light_theme_grey_6);
  -o-transition: none .12s ease;
  transition: none .12s;
  -o-transition-property: box-shadow, outline;
  font-family: var(--ioc_font_family_sans_serif);
  border-radius: 3px;
  transition-property: box-shadow, outline, -webkit-box-shadow;
  display: inline-flex;
  position: relative;
}

.IO-Components___Input__base--1xwxu:focus {
  -webkit-box-shadow: var(--ioc_focus_box_shadow);
  box-shadow: var(--ioc_focus_box_shadow);
  outline: 0;
}

.IO-Components___Input__base--1xwxu:active {
  outline: 0;
}

.IO-Components___Input__base--width-flex--2G5wH {
  width: 100%;
}

.IO-Components___Input__base--theme-dark--2gYRV {
  background-color: var(--ioc_dark_theme_grey_1);
  color: var(--ioc_dark_theme_grey_6);
  border: 1px solid var(--ioc_dark_theme_grey_3);
  box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, .05);
}

.IO-Components___Input__base--size-large--3-wgM {
  font-size: var(--ioc_font_size_1);
  height: var(--ioc_line_height_1);
}

.IO-Components___Input__base--size-medium--1PHPA {
  font-size: var(--ioc_font_size_0);
  height: var(--ioc_line_height_0);
}

.IO-Components___Input__base--size-small--380zn {
  font-size: var(--ioc_font_size_-1);
  height: var(--ioc_line_height_-1);
}

.IO-Components___Input__base--focused--a7BGw {
  -webkit-box-shadow: var(--ioc_focus_box_shadow);
  box-shadow: var(--ioc_focus_box_shadow);
  outline: 0;
}

.IO-Components___Input__base--state-disabled--3rH5T {
  pointer-events: none;
  border-color: hsla(var(--ioc_light_theme_grey_3_hsl), var(--ioc_opacity_disabled));
  background-color: hsla(var(--ioc_light_theme_grey_1_hsl), var(--ioc_opacity_disabled));
}

.IO-Components___Input__base--state-disabled--3rH5T.IO-Components___Input__base--theme-dark--2gYRV {
  border-color: hsla(var(--ioc_dark_theme_grey_3_hsl), var(--ioc_opacity_disabled));
  background-color: hsla(var(--ioc_dark_theme_grey_1_hsl), var(--ioc_opacity_disabled));
}

.IO-Components___Input__native-input--3DiG_ {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: inherit;
  font-size: inherit;
  font-family: var(--ioc_font_family_sans_serif);
  line-height: var(--ioc_line_height);
  background: none;
  border: 0;
  flex-grow: 1;
  padding: 0 9px;
}

.IO-Components___Input__native-input--3DiG_:focus {
  outline: 0;
}

.IO-Components___Input__extra-padding-for-clear--2I9oY {
  padding-right: 23px;
}

.IO-Components___Input__native-input--width-flex--2w0el {
  width: 100%;
}

.IO-Components___Input__child--1tFVV {
  align-items: center;
  display: flex;
}

.IO-Components___Input__child--1tFVV > * {
  height: 100%;
}

.IO-Components___Input__icon--2Mq-D {
  pointer-events: none;
  align-items: center;
  padding-left: 9px;
  display: flex;
}

.IO-Components___Input__icon--2Mq-D > * {
  color: inherit;
}

.IO-Components___Input__label--1jq88 {
  pointer-events: none;
  color: var(--ioc_light_theme_grey_5);
  white-space: nowrap;
  align-items: center;
  padding-left: 9px;
  display: flex;
}

.IO-Components___Input__input-wrapper--3LkkO {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.IO-Components___Input__recent-searches--25c6H {
  z-index: 1;
  margin-top: 10px;
  position: absolute;
  top: 100%;
  left: -2px;
  right: -2px;
}

.IO-Components___Input__text-area--SEzSi {
  -webkit-appearance: initial;
  -moz-appearance: initial;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100px;
  min-height: 50px;
  padding: 9px;
}

.IO-Components___Input__wrapper--1cXbD {
  position: relative;
}

.IO-Components___Input__clear--3VXQu {
  opacity: .5;
  -o-transition: opacity .1s ease-in-out;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  transition: opacity .1s ease-in-out;
  display: flex;
  position: absolute;
  top: 50%;
  right: 4px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.IO-Components___Input__clear--3VXQu:hover {
  opacity: 1;
}

.IO-Components___Input__base--error--Fd5r3 {
  border-color: var(--ioc_red);
}

.IO-Components___Toggle__base--f2En8 {
  --size-small: 14px;
  --size-medium: 17px;
  --size-large: 20px;
  --size: var(--size-medium);
  --background-light: var(--ioc_light_theme_grey_1);
  --background-dark: var(--ioc_dark_theme_grey_1);
  --background: var(--background-light);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color: var(--text-color-light);
  --font: var(--ioc_font_family_sans_serif);
  --border-color-light: var(--ioc_light_theme_grey_4);
  --border-color-dark: var(--ioc_dark_theme_grey_4);
  --border-color-hightlight: var(--ioc_bright_blue);
  --border-color: var(--border-color-light);
  font-family: var(--font);
  color: var(--text-color);
  font-size: var(--ioc_font_size_0);
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.IO-Components___Toggle__base--size-small--2INa9 {
  --size: var(--size-small);
  font-size: var(--ioc_font_size_-1);
}

.IO-Components___Toggle__base--size-large--1cZ_8 {
  --size: var(--size-large);
  font-size: var(--ioc_font_size_1);
}

.IO-Components___Toggle__base--theme-dark--3kEdV {
  --background: var(--background-dark);
  --text-color: var(--text-color-dark);
  --border-color: var(--border-color-dark);
}

.IO-Components___Toggle__base--alignment-center--mWGM7 {
  align-items: center;
}

.IO-Components___Toggle__base--alignment-top--2_jm7 {
  align-items: flex-start;
}

.IO-Components___Toggle__wrapper--XoW_i {
  vertical-align: middle;
  align-items: center;
  display: flex;
  position: relative;
}

.IO-Components___Toggle__toggle--12vek {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: var(--size);
  height: var(--size);
  -o-transition: all .12s ease;
  background: var(--background);
  border: 1px solid var(--border-color);
  cursor: pointer;
  margin: 0;
  transition: all .12s;
}

.IO-Components___Toggle__toggle--12vek:hover {
  border-color: var(--border-color-hightlight);
}

.IO-Components___Toggle__toggle--12vek:focus {
  -webkit-box-shadow: var(--ioc_focus_box_shadow);
  box-shadow: var(--ioc_focus_box_shadow);
  border-color: var(--border-color);
  outline: 0;
}

.IO-Components___Toggle__toggle--is-active--2T_XR {
  --background: var(--ioc_bright_blue);
  border-color: var(--border-color-hightlight);
}

.IO-Components___Toggle__toggle--is-active--2T_XR:focus {
  border-color: var(--border-color-hightlight);
}

.IO-Components___Toggle__base--state-disabled--3rEUf {
  pointer-events: none;
  opacity: var(--ioc_opacity_disabled);
}

.IO-Components___Toggle__base--style-row--1W3EC {
  padding: 10px;
}

.IO-Components___Toggle__base--style-row--1W3EC:hover {
  background-color: var(--ioc_light_theme_grey_2);
}

.IO-Components___Toggle__toggle--type-checkbox--2GLqk {
  border-radius: 3px;
}

.IO-Components___Toggle__toggle--type-radio--2FVVN {
  border-radius: 100%;
}

.IO-Components___Toggle__base--width-flex--QRuZz {
  width: 100%;
}

.IO-Components___Toggle__icon--2t0dD {
  -o-transition: all .12s ease;
  pointer-events: none;
  width: var(--size);
  height: var(--size);
  opacity: 0;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, .3);
  justify-content: center;
  align-items: center;
  transition: all .12s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.IO-Components___Toggle__dot--VunAz {
  -webkit-transform: scale(.6);
  transform: scale(.6);
}

.IO-Components___Toggle__icon--is-active--2SrUk {
  opacity: 1;
}

.IO-Components___Toggle__label--3CLC5 {
  line-height: var(--ioc_line_height);
  margin-left: 10px;
}

.IO-Components__RadioGroup__group--orientation-horizontal--3Ltew {
  align-items: center;
  display: flex;
}

.IO-Components__RadioGroup__group--orientation-vertical--1QeTN .IO-Components__RadioGroup__radio--3CaX0:not(:last-child) {
  margin-bottom: 5px;
}

.IO-Components__Pagination__base--1Ehku {
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --hover-color-light: rgba(0, 0, 0, .1);
  --hover-color-dark: rgba(255, 255, 255, .1);
  --hover-color: var(--hover-color-light);
  --height-small: var(--ioc_line_height_-1);
  --height-medium: var(--ioc_line_height_0);
  --height-large: var(--ioc_line_height_1);
  --height: var(--height-medium);
  --icon-size-small: 9px;
  --icon-size-medium: 11px;
  --icon-size-large: 13px;
  --icon-size: var(--icon-size-medium);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Soleil, Helvetica, Arial, sans-serif;
  display: inline-flex;
}

.IO-Components__Pagination__base--theme-dark--3dC3I {
  --text-color: var(--text-color-dark);
  --hover-color: var(--hover-color-dark);
}

.IO-Components__Pagination__base--size-large--35Ftd {
  font-size: var(--ioc_font_size_1);
  --height: var(--height-large);
  --icon-size: var(--icon-size-large);
}

.IO-Components__Pagination__base--size-medium--2V9qE {
  font-size: var(--ioc_font_size_0);
}

.IO-Components__Pagination__base--size-small--QF8tX {
  font-size: var(--ioc_font_size_-1);
  --height: var(--height-small);
  --icon-size: var(--icon-size-small);
}

.IO-Components__Pagination__link--txpYt {
  height: var(--height);
  width: var(--height);
  color: var(--text-color);
  font-size: inherit;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  display: flex;
}

.IO-Components__Pagination__link--txpYt:hover:not(.IO-Components__Pagination__link--is-active--gcoon) {
  background-color: var(--hover-color);
}

.IO-Components__Pagination__link--txpYt:focus {
  -webkit-box-shadow: var(--ioc_focus_box_shadow);
  box-shadow: var(--ioc_focus_box_shadow);
  outline: 0;
}

.IO-Components__Pagination__link--is-active--gcoon {
  background: var(--ioc_blue);
  color: #fff;
}

.IO-Components__Pagination__link--is-disabled--1EoNc {
  pointer-events: none;
  opacity: var(--ioc_opacity_disabled);
}

.IO-Components__Pagination__icon--1BvT0 {
  font-size: var(--icon-size);
  align-items: center;
  margin-top: 1px;
  display: flex;
}

.IO-Components__Pagination__spacer--1q4bB {
  width: var(--height);
  margin: 0 5px;
  display: block;
}

.IO-Components__withLocalPagination__container--2RvtM {
  flex-direction: column;
  display: flex;
}

.IO-Components__withLocalPagination__pagination--GThFL {
  order: 0;
  margin-top: 10px;
  display: inline-block;
}

.IO-Components__withLocalPagination__pagination--position-top--2Pgsb {
  order: -1;
  margin-top: 0;
  margin-bottom: 10px;
}

.IO-Components__withLocalPagination__pagination--alignment-center--1ZtP0 {
  align-self: center;
}

.IO-Components__withLocalPagination__pagination--alignment-left--_jRpH {
  align-self: flex-start;
}

.IO-Components__withLocalPagination__pagination--alignment-right--V822j {
  align-self: flex-end;
}

.IO-Components__Table__base--2VXmF {
  --size-small: 36px;
  --size-medium: 42px;
  --size-large: 52px;
  --size: var(--size-medium);
  --padding: 10px;
  --border-radius: 6px;
  --background-color--light: var(--ioc_light_theme_grey_0);
  --background-color--dark: var(--ioc_dark_theme_grey_0);
  --background-color: var(--background-color--light);
  --background-color-alt--light: var(--ioc_light_theme_grey_1);
  --background-color-alt-dark: var(--ioc_dark_theme_grey_1);
  --background-color-alt: var(--background-color-alt--light);
  --highlight-color--light: var(--ioc_light_theme_grey_2);
  --highlight-color--dark: var(--ioc_dark_theme_grey_0);
  --highlight-color: var(--highlight-color--light);
  --text-color--light: var(--ioc_light_theme_grey_6);
  --text-color--dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color--light);
  --border-color--light: var(--ioc_light_theme_grey_2);
  --border-color--dark: var(--ioc_dark_theme_grey_2);
  --border-color: var(--border-color--light);
  --col-resizer-color-light: var(--ioc_light_theme_grey_4);
  --col-resizer-color-dark: var(--ioc_dark_theme_grey_4);
  --col-resizer-color: var(--col-resizer-color-light);
  --col-resizer-hover-color-light: var(--text-color);
  --col-resizer-hover-color-dark: var(--ioc_dark_theme_grey_6);
  --col-resizer-hover-color: var(--col-resizer-hover-color-light);
  --cell-alignment: center;
  background-color: var(--background-color);
  color: var(--text-color);
  vertical-align: middle;
  font-size: var(--ioc_font_size_0);
  font-family: var(--ioc_font_family_sans_serif);
  border-radius: 3px;
  display: inline-block;
}

.IO-Components__Table__base--width-flexible--3a3VY {
  width: 100%;
}

.IO-Components__Table__base--height-flexible--1QnYr {
  height: 100%;
}

.IO-Components__Table__table--virtual--2Q5bY {
  flex-grow: 1;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
}

.IO-Components__Table__base--size-small--VLIqA {
  --padding: 5px;
  --size: var(--size-small);
  --border-radius: 0;
  font-size: var(--ioc_font_size_-1);
}

.IO-Components__Table__base--size-large--wsveQ {
  font-size: var(--ioc_font_size_1);
  --size: var(--size-large);
}

.IO-Components__Table__base--theme-dark--1FoI- {
  --background-color: var(--background-color--dark);
  --highlight-color: var(--highlight-color--dark);
  --text-color: var(--text-color--dark);
  --border-color: var(--border-color--dark);
  --background-color-alt: var(--background-color-alt-dark);
  --col-resizer-color: var(--col-resizer-color-dark);
  --col-resizer-hover-color: var(--col-resizer-hover-color-dark);
}

.IO-Components__Table__wrapper--3HJrh {
  height: 100%;
  display: flex;
}

.IO-Components__Table__table-container--z7Ggy {
  height: 100%;
}

.IO-Components__Table__table--1n8jj {
  table-layout: fixed;
  width: 100%;
  display: table;
}

.IO-Components__Table__row--1wQkJ {
  vertical-align: middle;
  display: table-row;
  position: relative;
}

.IO-Components__Table__base--data-table--2mZwI .IO-Components__Table__row--1wQkJ:nth-child(2n) {
  background: var(--background-color-alt);
}

.IO-Components__Table__base--data-table--2mZwI .IO-Components__Table__row--1wQkJ {
  vertical-align: top;
}

.IO-Components__Table__base--data-table--2mZwI .IO-Components__Table__row--1wQkJ:hover {
  background-color: var(--highlight-color);
}

.IO-Components__Table__row--clickable--FQLuG {
  cursor: pointer;
}

.IO-Components__Table__header--3KFss {
  display: table-row;
}

.IO-Components__Table__header-cell--7cAUE {
  display: table-cell;
}

.IO-Components__Table__header-cell--7cAUE:not(:last-of-type) {
  border-right: 1px solid var(--border-color);
}

.IO-Components__Table__cell--8rE4n {
  vertical-align: middle;
  padding: var(--padding);
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  display: table-cell;
}

.IO-Components__Table__header--3KFss .IO-Components__Table__cell--8rE4n {
  border-bottom: 1px solid var(--border-color);
  font-weight: bold;
}

.IO-Components__Table__row--1wQkJ:hover .IO-Components__Table__cell--8rE4n:not(.IO-Components__Table__header-cell--7cAUE) {
  background-color: var(--highlight-color);
}

.IO-Components__Table__base--data-table--2mZwI .IO-Components__Table__cell--8rE4n {
  vertical-align: top;
  word-break: break-word;
  white-space: initial;
}

.IO-Components__Table__cell--resizable--1Fr2j {
  overflow: hidden;
}

.IO-Components__Table__row--active--17eUV {
  background-color: #e1e3e5;
}

.IO-Components__Table__virtual-cell--25b_0 {
  padding: var(--padding);
  width: 100%;
  display: block;
  overflow-x: hidden;
}

.IO-Components__Table__cell--position-first--1gMhJ {
  padding-left: calc(var(--padding) * 2);
}

.IO-Components__Table__cell--position-last--qMh9q {
  padding-right: calc(var(--padding) * 2);
}

.IO-Components__Table__cell--align-left--qk_SC {
  text-align: left;
}

.IO-Components__Table__cell--align-center--P2vcL {
  text-align: center;
}

.IO-Components__Table__cell--align-right--1mYYq {
  text-align: right;
}

.IO-Components__Table__cell--border-bottom--xf_3U {
  border-bottom: 1px solid var(--border-color);
}

.IO-Components__Table__header-cell--is-sortable--1rK0q {
  cursor: pointer;
}

.IO-Components__Table__header-cell--is-sortable--1rK0q ::selection {
  background: none;
}

.IO-Components__Table__cell-content--2R0Eh {
  line-height: var(--ioc_line_height);
  align-items: center;
  display: flex;
}

.IO-Components__Table__cell-content--text-overflow-ellipsis--clPzH {
  overflow: hidden;
}

.IO-Components__Table__header-cell-content--3rjwx {
  text-transform: capitalize;
  font-weight: bold;
  line-height: var(--ioc_line_height);
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.IO-Components__Table__header-cell-content-overflow--2c4Ub {
  overflow: hidden;
}

.IO-Components__Table__header-cell-actions--1kO7e {
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.IO-Components__Table__header-overflow--NHyX6 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.IO-Components__Table__body--3kQYL, .IO-Components__Table__header--3KFss {
  padding: 10px;
  display: table-row-group;
}

.IO-Components__Table__arrow-icon--2YYcx {
  opacity: .6;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.IO-Components__Table__arrow-icon--active--23ZmD {
  opacity: 1;
}

.IO-Components__Table__remove-button--3cvkp {
  visibility: hidden;
  margin-left: 10px;
}

.IO-Components__Table__cell--8rE4n:hover .IO-Components__Table__remove-button--3cvkp {
  visibility: initial;
}

.IO-Components__Table__resizer--_tieP {
  color: var(--col-resizer-color);
  letter-spacing: -2px;
  cursor: col-resize;
  padding: 0 5px 0 10px;
  font-size: 20px;
  font-weight: 400;
}

.IO-Components__Table__resizer--_tieP:hover {
  color: var(--col-resizer-hover-color);
}

.IO-Components__Table__resizing--2wjgB {
  cursor: col-resize;
}

.ReactVirtualized__Table__Grid {
  border: 0;
  outline: 0;
}

.ReactVirtualized__Table__headerRow {
  text-transform: uppercase;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  display: flex;
}

.ReactVirtualized__Table__row {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ReactVirtualized__Table__headerTruncatedText {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  overflow-x: hidden;
}

.ReactVirtualized__Table__headerColumn:not(:last-of-type) {
  border-right: 1px solid var(--border-color);
}

.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
  min-width: 0;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  align-items: center;
  display: flex;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  fill: currentColor;
  flex: 0 0 24px;
  width: 1em;
  height: 1em;
}

.ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid var(--border-color);
  z-index: 1;
  position: relative;
  overflow: visible !important;
}

.ReactVirtualized__Table__row:hover {
  background: var(--highlight-color);
}

.IO-Components__EditableValueActionButtons__actions--1YZNP {
  justify-content: space-between;
  margin: 5px 0;
  display: flex;
}

.IO-Components__EditableValueActionButtons__button-group--v7R9a {
  justify-content: space-between;
  display: flex;
}

.IO-Components__EditableValueActionButtons__confirm-button--2WA6O {
  margin-right: 10px;
}

.IO-Components__EditableValueActionButtons__delete-button--1QTWC {
  margin-left: 10px;
}

.IO-Components__EditableValue__base--3H6PT {
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  font-size: var(--ioc_font_size_0);
  font-family: var(--ioc_font_family_sans_serif);
  color: var(--text-color);
  width: 100%;
}

.IO-Components__EditableValue__base--size-small--1ks7F {
  font-size: var(--ioc_font_size_-1);
}

.IO-Components__EditableValue__base--size-large--2XFiC {
  font-size: var(--ioc_font_size_1);
}

.IO-Components__EditableValue__base--theme-dark--1YJlW {
  --text-color: var(--text-color-dark);
}

.IO-Components__EditableValue__editor--2Sbem {
  display: none;
}

.IO-Components__EditableValue__editor--active--2j3rC {
  align-items: center;
  display: flex;
}

.IO-Components__EditableValue__base--multiline--11GpV .IO-Components__EditableValue__editor--active--2j3rC {
  display: block;
}

.IO-Components__EditableValue__value--2g7fY {
  cursor: pointer;
  word-break: break-all;
  width: 100%;
  display: flex;
}

.IO-Components__EditableValue__value--ellipsis--3_itO {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.IO-Components__EditableValue__edit-icon--1o74c {
  opacity: .4;
  margin-left: 10px;
  display: inline-block;
}

.IO-Components__EditableValue__value--2g7fY:hover .IO-Components__EditableValue__edit-icon--1o74c {
  opacity: .8;
}

.IO-Components__EditableValue__value--inactive--1hia0 {
  display: none;
}

.IO-Components__EditableValue__input--Ni71N {
  width: 100%;
  margin-right: 10px;
}

.IO-Components__EditableValue__delete--1-5wR {
  margin-left: 10px;
}

.IO-Components__EditableValue__textarea--3V30Z {
  margin-top: 3px;
}

.IO-Components__EditableValue__actions--3mgxE {
  justify-content: space-between;
  margin: 5px 0;
  display: flex;
}

.IO-Components__EditableValue__button-group--2lfCK {
  justify-content: space-between;
  display: flex;
}

.IO-Components__EditableValue__confirm-button--3hCAN {
  margin-right: 10px;
}

.IO-Components__Title__base--J_7XY {
  --size-h0: 2em;
  --size-h1: 1.6em;
  --size-h2: 1.2em;
  --size: var(--size-h2);
  font-size: var(--size);
  font-weight: var(--ioc_font_weight_bold);
  font-family: var(--ioc_font_family_sans_serif);
  display: block;
}

.IO-Components__Title__base--h0--1dUyM {
  --size: var(--size-h0);
}

.IO-Components__Title__base--h1--3iE4f {
  --size: var(--size-h1);
}

.IO-Components___Box__variables--4Pyxq {
  --background-color-light: var(--ioc_light_theme_grey_2);
  --background-color-dark: var(--ioc_dark_theme_grey_2);
  --background-color: var(--background-color-light);
  --content-box-background-color-light: var(--ioc_light_theme_grey_0);
  --content-box-background-color-dark: var(--ioc_dark_theme_grey_0);
  --content-box-background-color: var(--content-box-background-color-light);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --padding: 15px;
}

.IO-Components___Box__base--1Q1_d {
  color: var(--text-color);
  background: var(--background-color);
  font-family: var(--ioc_font_family_sans_serif);
  padding: var(--padding);
  border-radius: 5px;
  display: block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .18);
}

.IO-Components___Box__base--theme-dark--2vZ5a {
  --background-color: var(--background-color-dark);
  --content-box-background-color: var(--content-box-background-color-dark);
  --text-color: var(--text-color-dark);
}

.IO-Components___Box__content--2sPbi {
  border-radius: 5px;
}

.IO-Components___Box__flexible-height--2shCq .IO-Components___Box__content--2sPbi {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.IO-Components___Box__content-box--s_ms4 {
  padding: var(--padding);
  background: var(--content-box-background-color);
  flex-grow: 1;
}

.IO-Components___Box__content-box-hover--2y7A7:hover {
  background: #e7e7e7;
}

.IO-Components___Box__header--1TKb7 {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 20px;
  display: flex;
}

.IO-Components___Box__header--no-children--1lPOF {
  padding: 10px 0 15px;
}

.IO-Components___Box__title--DTBBN {
  align-items: center;
  display: flex;
}

.IO-Components___Box__icon--3TBs6 {
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.IO-Components___Box__actions--3vLPJ {
  margin-top: var(--padding);
  display: flex;
}

.IO-Components___Box__flexible-height--2shCq {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.IO-Components__Tag__variables--WqnKP {
  --background-color-light: var(--ioc_light_theme_grey_1);
  --background-color-dark: var(--ioc_dark_theme_grey_3);
  --background-color-highlight: var(--ioc_blue);
  --background-color: var(--background-color-light);
  --background-secondary-light: var(--ioc_light_theme_grey_2);
  --background-secondary-dark: var(--ioc_dark_theme_grey_4);
  --background-secondary-highlight: var(--ioc_blue_1);
  --background-secondary: var(--background-secondary-light);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --height-small: var(--ioc_line_height_-1);
  --height-medium: var(--ioc_line_height_0);
  --height-large: var(--ioc_line_height_1);
  --height: var(--height-medium);
  --font-size-small: var(--ioc_font_size_-1);
  --font-size-medium: var(--ioc_font_size_0);
  --font-size-large: var(--ioc_font_size_1);
  --font-size: var(--font-size-medium);
}

.IO-Components__Tag__base--UQhf8 {
  color: var(--text-color);
  font-size: var(--font-size);
  font-family: var(--ioc_font_family_sans_serif);
  background-color: var(--background-color);
  height: var(--height);
  border-radius: 50px;
  align-items: center;
  display: inline-flex;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
}

.IO-Components__Tag__tag--3f41_ {
  line-height: var(--ioc_line_height);
  align-items: center;
  padding: 0 10px;
  display: inline-flex;
}

.IO-Components__Tag__icon--3staw {
  align-items: center;
  margin-top: 1px;
  margin-right: 5px;
  display: flex;
}

.IO-Components__Tag__base--size-small--3WIGW {
  --font-size: var(--font-size-small);
  --height: var(--height-small);
}

.IO-Components__Tag__base--size-large--1cZ7d {
  --font-size: var(--font-size-large);
  --height: var(--height-large);
}

.IO-Components__Tag__base--theme-dark--2B7yp {
  --text-color: var(--text-color-dark);
  --background-color: var(--background-color-dark);
  --background-secondary: var(--background-secondary-dark);
}

.IO-Components__Tag__base--theme-highlight--2byl3 {
  --background-color: var(--background-color-highlight);
  --text-color: var(--text-color-dark);
  --background-secondary: var(--background-secondary-highlight);
}

.IO-Components__Tag__remove--3DGDB {
  height: var(--height);
  background: var(--background-secondary);
  cursor: pointer;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.IO-Components__Tag__remove--3DGDB:hover {
  background: none;
}

.IO-Components__EmptyState__variables--Ix2JZ {
  --background-color-light: var(--ioc_light_theme_grey_2);
  --background-color-dark: var(--ioc_dark_theme_grey_2);
  --background-color: var(--background-color-light);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --padding: 15px;
}

.IO-Components__EmptyState__base--25DPx {
  color: var(--text-color);
  background: var(--background-color);
  font-family: var(--ioc_font_family_sans_serif);
  padding: var(--padding);
  font-size: var(--io-components_font_size_0);
  height: inherit;
  border-radius: 5px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  display: flex;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .18);
}

.IO-Components__EmptyState__base--theme-dark--2TC_3 {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
}

.IO-Components__EmptyState__flexible-height--3Ct3i {
  flex-grow: 1;
}

.IO-Components__Label__base--3LE1p {
  text-transform: uppercase;
  font-weight: var(--ioc_font_weight_bold);
  margin-bottom: 10px;
  font-size: .85em;
  display: block;
}

.IO-Components__Label__base--3LE1p .IO-Components__Label__label--has-severity--1FU0o {
  border-radius: 5px;
  padding: 3px 8px;
}

.IO-Components__Label__label--has-severity--1FU0o {
  color: var(--ioc_light_theme_grey_0);
}

.IO-Components__Label__label--danger--1cibV {
  background: var(--ioc_severity_danger);
}

.IO-Components__Label__label--warning--2MzmO {
  background: var(--ioc_severity_warning);
}

.IO-Components__Label__label--info--3Tnd- {
  background: var(--ioc_severity_info);
}

.IO-Components__Label__label--success--3GpWi {
  background: var(--ioc_severity_success);
}

.IO-Components__Label__label--neutral--iuu4q {
  background: var(--ioc_severity_neutral);
}

.IO-Components__FileUpload__native-input--197zJ {
  display: none;
}

.IO-Components__FileUpload__label--2QxIK {
  margin-left: 5px;
}

.IO-Components__LoadingTile__loader--2djBb {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.IO-Components__LoadingTile__row--2aLDv {
  align-self: center;
  padding: 5px;
  display: flex;
}

.IO-Components__RoundIconButton__base--3g7a1 {
  --round-icon-bg-color: var(--ioc_light_theme_grey_4);
  --round-icon-bg-color-hover: var(--ioc_light_theme_grey_5);
  cursor: pointer;
  color: var(--ioc_light_theme_grey_2);
  background-color: var(--round-icon-bg-color);
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  margin-left: 10px;
  display: flex;
}

.IO-Components__RoundIconButton__base--3g7a1:hover {
  color: var(--ioc_light_theme_grey_3);
  background-color: var(--round-icon-bg-color-hover);
}

.IO-Components__RoundIconButton__base--transparent--20csI {
  --round-icon-bg-color: rgba(0, 0, 0, .2);
  --round-icon-bg-color-hover: rgba(0, 0, 0, .5);
}

.IO-Components__RoundIconButton__base--size-small--12yDa {
  font-size: var(--ioc_font_size_-1);
  width: 21px;
  height: 21px;
}

.IO-Components__RoundIconButton__base--size-large--wGEcO {
  font-size: var(--ioc_font_size_1);
  width: 26px;
  height: 26px;
}

.IO-Components__RoundIconButton__base--theme-dark--2ttFM {
  color: var(--ioc_dark_theme_grey_2);
  background-color: var(--ioc_dark_theme_grey_4);
}

.IO-Components__RoundIconButton__base--theme-dark--2ttFM:hover {
  color: var(--ioc_dark_theme_grey_3);
  background-color: var(--ioc_dark_theme_grey_5);
}

.IO-Components__Banner__base--nGcSS {
  -webkit-box-shadow: var(--ioc_light_theme_shadow);
  box-shadow: var(--ioc_light_theme_shadow);
  font-family: var(--ioc_font_family_sans_serif);
  box-sizing: border-box;
  padding: 15px 18px;
}

.IO-Components__Banner__base--size-small--AJ63g {
  padding: 5px 6px;
}

.IO-Components__Banner__base--size-small--AJ63g .IO-Components__Banner__content--3PiSj {
  font-size: var(--ioc_font_size_0);
}

.IO-Components__Banner__base--size-large--1pdfO {
  padding: 20px 25px;
}

.IO-Components__Banner__base--size-large--1pdfO .IO-Components__Banner__content--3PiSj {
  font-size: var(--ioc_font_size_2);
}

.IO-Components__Banner__base--theme-dark--2o30K {
  -webkit-box-shadow: var(--ioc_dark_theme_shadow);
  box-shadow: var(--ioc_dark_theme_shadow);
}

.IO-Components__Banner__banner--fhQ12 {
  color: var(--ioc_dark_theme_grey_0);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.IO-Components__Banner__banner--danger--3mZ9g {
  background: var(--ioc_severity_danger);
  color: var(--ioc_light_theme_grey_0);
}

.IO-Components__Banner__banner--warning--JiMsu {
  background: var(--ioc_severity_warning);
}

.IO-Components__Banner__banner--info--3Vm8l {
  background: var(--ioc_severity_info);
  color: var(--ioc_light_theme_grey_0);
}

.IO-Components__Banner__banner--success--2McOC {
  background: var(--ioc_severity_success);
}

.IO-Components__Banner__banner--neutral--21XZH {
  background: var(--ioc_severity_neutral);
  color: var(--ioc_light_theme_grey_0);
}

.IO-Components__Banner__content--3PiSj {
  text-align: center;
  flex: 1;
  padding-left: 33px;
}

.IO-Components__Banner__content--3PiSj p {
  font-weight: 700;
}

.IO-Components__Modal__confirm-button-container--hGW4i {
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  width: 100%;
  display: flex;
}

.IO-Components__Modal__actions-container--1YfIt {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.IO-Components__Modal__actions-container--no-cancel--1ueE9 {
  justify-content: flex-end;
}

.IO-Components__Modal__overlay--1vkyw {
  z-index: 1000;
  background: rgba(0, 0, 0, .8);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.IO-Components__Modal__container--1d6l6 {
  z-index: 1000;
  min-width: 500px;
  display: flex;
}

.IO-Components__BlankModal__confirm-button-container--1UIF0 {
  justify-content: stretch;
  width: 100%;
  display: flex;
}

.IO-Components__BlankModal__actions-container--3Cd1o {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.IO-Components__BlankModal__actions-container--no-cancel--27qza {
  justify-content: flex-end;
}

.IO-Components__BlankModal__overlay--pS3UZ {
  z-index: 1000;
  background: rgba(0, 0, 0, .8);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.IO-Components__BlankModal__container--2-GxW {
  z-index: 1000;
  width: auto;
  min-width: 500px;
  display: flex;
}

.IO-Components__LoadingModal__confirm-button-container--NKj_n {
  justify-content: stretch;
  width: 100%;
  display: flex;
}

.IO-Components__LoadingModal__actions-container--3J4q_ {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.IO-Components__LoadingModal__actions-container--no-cancel--1LhlM {
  justify-content: flex-end;
}

.IO-Components__LoadingModal__overlay--RVKAr {
  z-index: 1000;
  background: rgba(0, 0, 0, .8);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.IO-Components__LoadingModal__container--3Wrxp {
  z-index: 1000;
  width: 500px;
  min-width: 500px;
  display: flex;
}

.IO-Components__StatusCircle__variables--hXEJv {
  --size-small: 8px;
  --size-medium: 10px;
  --size-large: 16px;
  --size: var(--size-medium);
  --background-color-status-success: var(--ioc_green);
  --background-color-status-info: var(--ioc_blue);
  --background-color-status-warning: var(--ioc_orange);
  --background-color-status-danger: var(--ioc_red);
  --background-color-status-inactive: var(--ioc_light_theme_grey_4);
  --background-color: var(--background-color-status-info);
}

.IO-Components__StatusCircle__base--WWuzq {
  width: var(--size);
  height: var(--size);
  background-color: var(--background-color);
  border-radius: 999999px;
  flex-grow: 0;
  flex-shrink: 0;
}

.IO-Components__StatusCircle__base--size-small--2tSdM {
  --size: var(--size-small);
}

.IO-Components__StatusCircle__base--size-medium--36ld4 {
  --size: var(--size-medium);
}

.IO-Components__StatusCircle__base--size-large--2szDH {
  --size: var(--size-large);
}

.IO-Components__StatusCircle__base--status-success--3zKqa {
  --background-color: var(--background-color-status-success);
}

.IO-Components__StatusCircle__base--status-info--1d_K3 {
  --background-color: var(--background-color-status-info);
}

.IO-Components__StatusCircle__base--status-warning--1BEEt {
  --background-color: var(--background-color-status-warning);
}

.IO-Components__StatusCircle__base--status-danger--wHl1G {
  --background-color: var(--background-color-status-danger);
}

.IO-Components__StatusCircle__base--status-inactive--3I-yQ {
  --background-color: var(--background-color-status-inactive);
}

.IO-Components__TreeViewItem__base--1aOyQ {
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --hover-color-light: var(--ioc_light_theme_grey_2);
  --hover-color-dark: var(--ioc_dark_theme_grey_2);
  --hover-color: var(--hover-color-light);
  --text-size-small: var(--ioc_font_size_-1);
  --text-size-medium: var(--ioc_font_size_0);
  --text-size-large: var(--ioc_font_size_1);
  --text-size: var(--text-size-medium);
  color: var(--text-color);
  font-size: var(--text-size);
  font-family: var(--ioc_font_family_sans_serif);
}

.IO-Components__TreeViewItem__base--theme-dark--3ww4S {
  --text-color: var(--text-color-dark);
  --hover-color: var(--hover-color-dark);
}

.IO-Components__TreeViewItem__base--size-small--2sTh8 {
  --text-size: var(--text-size-small);
}

.IO-Components__TreeViewItem__base--size-large--3SU6F {
  --text-size: var(--text-size-large);
}

.IO-Components__TreeViewItem__label--3VmpR {
  cursor: pointer;
  border-bottom: 1px solid var(--ioc_dark_theme_grey_3);
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.IO-Components__TreeViewItem__label--3VmpR:hover {
  background-color: var(--hover-color);
}

.IO-Components__TreeViewItem__icons--3n9mL {
  align-items: center;
  display: flex;
}

.IO-Components__TreeViewItem__icons--3n9mL div:first-child svg {
  width: 5px;
}

.IO-Components__TreeViewItem__icons--3n9mL div:last-child svg {
  width: 1em;
}

.IO-Components__TreeViewItem__icons--3n9mL svg {
  margin-right: 5px;
}

.IO-Components__TreeViewItem__children--2S6Z5 {
  padding-left: 20px;
}

.IO-Components__Tab__base--1D4EY {
  --background-color-light: var(--ioc_light_theme_grey_2);
  --background-color-dark: var(--ioc_dark_theme_grey_2);
  --background-color: var(--background-color-light);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --icon-hover-color-light: var(--ioc_light_theme_grey_5);
  --icon-hover-color-dark: var(--ioc_dark_theme_grey_5);
  --icon-hover-color: var(--icon-hover-color-light);
  --inactive-tab-color-light: var(--ioc_light_theme_grey_3);
  --inactive-tab-color-dark: var(--ioc_dark_theme_grey_4);
  --inactive-tab-color: var(--inactive-tab-color-light);
  --border-radius: 1px;
  --font-size-small: var(--ioc_font_size_-1);
  --font-size: var(--ioc_font_size_0);
}

.IO-Components__Tab__container--12vDU {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, .18));
  flex-direction: column;
  height: 100%;
  display: flex;
}

.IO-Components__Tab__base--theme-dark--CCtnf {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --inactive-tab-color: var(--inactive-tab-color-dark);
  --icon-hover-color: var(--icon-hover-color-dark);
}

.IO-Components__Tab__base--size-small--2h1kg {
  --font-size: var(--font-size-small);
}

.IO-Components__Tab__tab--QtX_w {
  font-size: var(--font-size);
  color: var(--text-color);
  background: var(--background-color);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  font-family: var(--ioc_font_family_sans_serif);
  cursor: pointer;
  border-bottom: 0;
  align-items: center;
  padding: 5px 10px 10px;
  display: flex;
  position: relative;
}

.IO-Components__Tab__tab--QtX_w:not(:last-child) {
  margin-right: 5px;
}

.IO-Components__Tab__tab--QtX_w:not(.IO-Components__Tab__tab--active--2Vz8W) {
  background: var(--inactive-tab-color);
}

.IO-Components__Tab__tab--QtX_w:not(.IO-Components__Tab__tab--active--2Vz8W):after {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .3)));
  background: -o-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .3));
  content: "";
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .3));
  height: 5px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.IO-Components__Tab__tab-title--2xuUq {
  flex-grow: 1;
}

.IO-Components__Tab__close--18XXJ {
  cursor: pointer;
  align-items: center;
  margin-left: 5px;
  padding: 2px;
  font-size: .8em;
  display: flex;
}

.IO-Components__Tab__close--18XXJ:hover {
  color: var(--icon-hover-color);
}

.IO-Components__TabbedBox__base--wfXBz {
  --background-color-light: var(--ioc_light_theme_grey_2);
  --background-color-dark: var(--ioc_dark_theme_grey_2);
  --background-color: var(--background-color-light);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --icon-hover-color-light: var(--ioc_light_theme_grey_5);
  --icon-hover-color-dark: var(--ioc_dark_theme_grey_5);
  --icon-hover-color: var(--icon-hover-color-light);
  --inactive-tab-color-light: var(--ioc_light_theme_grey_3);
  --inactive-tab-color-dark: var(--ioc_dark_theme_grey_4);
  --inactive-tab-color: var(--inactive-tab-color-light);
  --border-radius: 1px;
  --padding: 15px;
  --font-size-small: var(--ioc_font_size_-1);
  --font-size: var(--ioc_font_size_0);
}

.IO-Components__TabbedBox__container--2fvYX {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, .18));
  flex-direction: column;
  height: 100%;
  display: flex;
}

.IO-Components__TabbedBox__base--theme-dark--3g17R {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --inactive-tab-color: var(--inactive-tab-color-dark);
  --icon-hover-color: var(--icon-hover-color-dark);
}

.IO-Components__TabbedBox__base--size-small--3LLQG {
  --font-size: var(--font-size-small);
}

.IO-Components__TabbedBox__content--PzVvu {
  color: var(--text-color);
  background: var(--background-color);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  font-family: var(--ioc_font_family_sans_serif);
  padding: var(--padding) var(--padding) 0;
  flex-grow: 1;
  flex-shrink: 1;
  display: block;
  overflow: hidden;
}

.IO-Components__TabbedBox__tabs--hovox {
  flex-shrink: 1;
  margin-right: 5px;
  padding-bottom: 15px;
  display: flex;
  position: relative;
  overflow: auto;
}

.IO-Components__TabbedBox__nav--2xN4R {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  display: flex;
}

.IO-Components__TabbedBox__actions--p-Df_ {
  display: flex;
}

.IO-Components__InlineTabs__base--oAdcc {
  text-align: center;
  flex-direction: column;
  height: 100%;
  display: flex;
}

.IO-Components__InlineTabs__tab--2aZ83 {
  text-transform: uppercase;
  color: var(--ioc_blue);
  cursor: pointer;
  font-family: var(--ioc_font_family_sans_serif);
  border-bottom: 2px solid;
  flex-shrink: 1;
  margin: 0 3px;
  padding: 5px 2px;
  display: inline-block;
}

.IO-Components__InlineTabs__tab--2aZ83:not(.IO-Components__InlineTabs__tab--active--2wEVJ) {
  border-bottom: 0;
}

.IO-Components__InlineTabs__tabs--15lEh {
  flex-shrink: 1;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  overflow: auto;
}

.IO-Components__InlineTabs__content--3PjE5 {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}

.IO-Components__InlineTabs__header--alignment-center--E5EmF {
  align-self: center;
}

.IO-Components__InlineTabs__header--alignment-flex-start--30gI- {
  align-self: flex-start;
}

.IO-Components__InlineTabs__header--alignment-flex-end--3sF0E {
  align-self: flex-end;
}

.IO-Components__InlineTabs__tab--alignment-center--3n0TP {
  align-self: center;
}

.IO-Components__InlineTabs__tab--alignment-flex-start--1vqU6 {
  align-self: flex-start;
}

.IO-Components__InlineTabs__tab--alignment-flex-end--1DH8Y {
  align-self: flex-end;
}

.IO-Components__TimePicker__base--2afUc {
  font-size: var(--ioc_font_size_0);
  color: var(--ioc_light_theme_grey_6);
  align-items: center;
  display: flex;
}

.IO-Components__TimePicker__base--theme-dark--3eA6p {
  color: var(--ioc_dark_theme_grey_6);
}

.IO-Components__TimePicker__base--size-small--3Gr_V {
  font-size: var(--ioc_font_size_-1);
}

.IO-Components__TimePicker__base--size-large--2Oqax {
  font-size: var(--ioc_font_size_0);
}

.IO-Components__TimePicker__icon--28pDr {
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.IO-Components__TimePicker__separator--1fHK7 {
  margin: 0 5px;
}

.IO-Components__DatePicker__base--1YCXh {
  color: var(--ioc_light_theme_grey_6);
  --font_size: var(--ioc_font_size_0);
  font-family: var(--ioc_font_family_sans_serif);
}

.IO-Components__DatePicker__base--theme-dark--eKcwg {
  color: var(--ioc_dark_theme_grey_6);
}

.IO-Components__DatePicker__base--size-small--1BVu9 {
  --font_size: var(--ioc_font_size_-1);
}

.IO-Components__DatePicker__base--size-large--3E2hR {
  --font_size: var(--ioc_font_size_1);
}

.IO-Components__DateInput__base--1CVb9 {
  --font_size: var(--ioc_font_size_0);
  font-family: var(--ioc_font_family_sans_serif);
}

.IO-Components__DateInput__base--size-small--21vFW {
  --font_size: var(--ioc_font_size_-1);
}

.IO-Components__DateInput__base--size-large--2b49b {
  --font_size: var(--ioc_font_size_1);
}

.IO-Components__DateInput__base--width-flex--23fxy {
  width: 100%;
}

.IO-Components__DateInput__time--3GNWH {
  justify-content: center;
  display: flex;
}

.DayPicker {
  --ioc_color_primary: var(--ioc_light_theme_grey_6);
  --ioc_color_secondary: var(--ioc_light_theme_grey_4);
  font-size: var(--font_size);
  display: inline-block;
}

.DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  padding-bottom: 1em;
  position: relative;
}

.DayPicker-wrapper:focus {
  outline: none;
}

.DayPicker-Months {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.DayPicker-Month {
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  user-select: none;
  margin: 1em 1em 0;
  display: table;
}

.DayPicker-NavButton {
  color: var(--ioc_nav_button_color);
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 1.25em;
  height: 1.25em;
  margin-top: 2px;
  display: inline-block;
  position: absolute;
  top: 1em;
  left: auto;
  right: 1.5em;
}

.DayPicker-NavButton:hover {
  opacity: .8;
}

.DayPicker-NavButton--prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  margin-right: 1.5em;
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  text-align: left;
  margin-bottom: .5em;
  padding: 0 .5em;
  display: table-caption;
}

.DayPicker-Caption > div {
  font-size: 1.15em;
  font-weight: 500;
}

.DayPicker-Weekdays {
  margin-top: 1em;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  color: var(--ioc_color_secondary);
  text-align: center;
  padding: .5em;
  font-size: .875em;
  display: table-cell;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  padding: .5em;
  display: table-cell;
}

.DayPicker-Day:focus {
  outline: 0;
}

.DayPicker-WeekNumber {
  color: var(--ioc_color_secondary);
  vertical-align: middle;
  text-align: right;
  cursor: pointer;
  border-right: 1px solid #eaecec;
  min-width: 1em;
  padding: .5em;
  font-size: .75em;
  display: table-cell;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: .5em;
}

.DayPicker-TodayButton {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border: none;
  font-size: .875em;
}

.DayPicker-Day--today {
  font-weight: 700;
}

.DayPicker-Day--outside {
  visibility: hidden;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}

.DayPicker-Day--active {
  background-color: var(--ioc_blue);
  color: #fff;
}

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  z-index: 1;
  background: #fff;
  position: absolute;
  left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
}

.IO-Components__DateInput__submit--2TlNF {
  margin-top: 10px;
}

.IO-Components__TextWarning__icon--1gX1S {
  margin-right: 10px;
  display: flex;
}

.IO-Components__TextWarning__icon--danger--wyGPv {
  color: var(--ioc_severity_danger);
}

.IO-Components__TextWarning__icon--warning--q-PYI {
  color: var(--ioc_severity_warning);
}

.IO-Components__TextWarning__warning--19-y_ {
  font-family: var(--ioc_font_family_sans_serif);
  line-height: var(--ioc_line_height);
  align-content: center;
  margin: 10px 0 0;
  display: flex;
}

.IO-Components__TextWarning__label--2s6Ky {
  display: flex;
}

.IO-Components__FloatingTooltip__variables--1wb0B {
  --background-color-light: var(--ioc_light_theme_grey_1);
  --background-color-dark: var(--ioc_dark_theme_grey_2);
  --background-color-menu: var(--ioc_navy_-1);
  --background-color: var(--background-color-light);
  --text-color-light: var(--ioc_light_theme_grey_6);
  --text-color-dark: var(--ioc_dark_theme_grey_6);
  --text-color: var(--text-color-light);
  --arrow-size-small: 8px;
  --arrow-size-medium: 10px;
  --arrow-size-large: 12px;
  --arrow-size: var(--arrow-size-medium);
  --padding-small: 8px;
  --padding-medium: 12px;
  --padding-large: 18px;
  --padding: var(--padding-medium);
  --outline-shadow-light: drop-shadow(0 0 1px rgba(0, 0, 0, .2));
  --outline-shadow-dark: drop-shadow(0 0 1px rgba(0, 0, 0, .6));
  --outline-shadow: var(--outline-shadow-light);
  --border-radius-small: 2px;
  --border-radius-medium: 4px;
  --border-radius-large: 6px;
  --border-radius: var(--border-radius-medium);
  --menu-item-padding-small: 8px;
  --menu-item-padding-medium: 12px;
  --menu-item-padding-large: 18px;
  --menu-item-padding: var(--menu-item-padding-medium);
  --menu-item-hover-color-light: var(--ioc_light_theme_grey_4);
  --menu-item-hover-color-dark: var(--ioc_dark_theme_grey_4);
  --menu-item-hover-color: var(--menu-item-hover-color-light);
  --offset: 5px;
}

.IO-Components__FloatingTooltip__popper-wrapper--1bmZ2 {
  pointer-events: none;
}

.IO-Components__FloatingTooltip__base--1MpH0 {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  -o-transition: opacity .1s ease;
  -webkit-filter: var(--outline-shadow) drop-shadow(0 9px 10px rgba(0, 0, 0, .1));
  filter: var(--outline-shadow) drop-shadow(0 9px 10px rgba(0, 0, 0, .1));
  color: var(--text-color);
  margin-top: calc(var(--offset) * -1);
  transition: opacity .1s;
  position: relative;
}

[data-popper-placement="right-end"] .IO-Components__FloatingTooltip__base--1MpH0, [data-popper-placement="left-end"] .IO-Components__FloatingTooltip__base--1MpH0 {
  margin-top: 0;
  margin-bottom: calc(var(--offset) * -1);
}

[data-popper-placement="left-start"] .IO-Components__FloatingTooltip__base--1MpH0 {
  margin-top: calc(var(--offset) * -1);
}

.IO-Components__FloatingTooltip__base--theme-dark--3vVbi {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --outline-shadow: var(--outline-shadow-dark);
  --menu-item-hover-color: var(--menu-item-hover-color-dark);
}

.IO-Components__FloatingTooltip__base--theme-header--1UKvB {
  --background-color: var(--background-color-menu);
  --text-color: var(--text-color-dark);
  --outline-shadow: var(--outline-shadow-dark);
}

.IO-Components__FloatingTooltip__base--size-small--3JMTM {
  font-size: var(--ioc_font_size_-1);
  --arrow-size: var(--arrow-size-small);
  --padding: var(--padding-small);
  --border-radius: var(--border-radius-small);
  --menu-item-padding: var(--menu-item-padding-small);
}

.IO-Components__FloatingTooltip__base--size-medium--fSMLb {
  font-size: var(--ioc_font_size_0);
  --arrow-size: var(--arrow-size-medium);
  --padding: var(--padding-medium);
  --border-radius: var(--border-radius-medium);
  --menu-item-padding: var(--menu-item-padding-medium);
}

.IO-Components__FloatingTooltip__base--size-large--3fX5T {
  font-size: var(--ioc_font_size_1);
  --arrow-size: var(--arrow-size-large);
  --padding: var(--padding-large);
  --border-radius: var(--border-radius-large);
  --menu-item-padding: var(--menu-item-padding-large);
}

.IO-Components__FloatingTooltip__base--active--3dRLi {
  pointer-events: initial;
  visibility: initial;
  opacity: 1;
}

.IO-Components__FloatingTooltip__popper--active--L8V-H .IO-Components__FloatingTooltip__base--active--3dRLi {
  pointer-events: none;
}

[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.IO-Components__FloatingTooltip__content--2hKeX {
  background-color: var(--background-color);
}

.IO-Components__FloatingTooltip__arrow--2-_tp {
  background-color: var(--background-color);
  z-index: -1;
  margin-top: var(--offset);
  width: 10px;
  height: 10px;
  position: absolute;
  right: 100%;
  -webkit-transform: translate(5px, 2.5px)rotate(45deg);
  transform: translate(5px, 2.5px)rotate(45deg);
}

[data-popper-placement="right-end"] .IO-Components__FloatingTooltip__arrow--2-_tp {
  margin-top: auto;
  margin-bottom: var(--offset);
  top: auto;
  bottom: 0;
  -webkit-transform: translate(5px, -2.5px)rotate(45deg);
  transform: translate(5px, -2.5px)rotate(45deg);
}

[data-popper-placement="left-end"] .IO-Components__FloatingTooltip__arrow--2-_tp {
  right: initial;
  margin-top: auto;
  margin-bottom: var(--offset);
  bottom: 0;
  left: 100%;
  -webkit-transform: translate(-5px, -2.5px)rotate(45deg);
  transform: translate(-5px, -2.5px)rotate(45deg);
}

[data-popper-placement="left-start"] .IO-Components__FloatingTooltip__arrow--2-_tp {
  right: initial;
  margin-top: var(--offset);
  top: 0;
  left: 100%;
  -webkit-transform: translate(-5px, 2.5px)rotate(45deg);
  transform: translate(-5px, 2.5px)rotate(45deg);
}

.IO-Components__FloatingTooltip__menu-item--RgBJd {
  white-space: nowrap;
  padding: var(--menu-item-padding);
  cursor: pointer;
  font-size: var(--font-size);
}

.IO-Components__FloatingTooltip__menu-item--RgBJd:hover {
  background: var(--menu-item-hover-color);
}

.IO-Components__ContextualHelpTooltip__content--2p6SI {
  font-family: var(--ioc_font_family_sans_serif);
  line-height: var(--ioc_line_height);
  max-width: 400px;
  padding: 10px;
}

.IO-Components__ContextualHelpTooltip__icon--2dvm1 {
  color: var(--ioc_dark_theme_grey_5);
  cursor: pointer;
}

.IO-Components__ContextualHelpTooltip__icon--2dvm1:hover {
  color: var(--ioc_dark_theme_grey_6);
}

.IO-Components__withCarousel__base--2dZF6, .IO-Components__withCarousel__selected--19Y-i {
  --dot-padding-small: 4px;
  --dot-padding-medium: 6px;
  --dot-padding-large: 8px;
  --dots-margin-small: calc(var(--dot-padding-small) / 2);
  --dots-margin-medium: calc(var(--dot-padding-medium) / 2);
  --dots-margin-large: calc(var(--dot-padding-large) / 2);
  --dots-color: var(--ioc_light_theme_grey_4);
  --dots-color-dark: var(--ioc_dark_theme_grey_4);
  --selected-color: var(--ioc_light_theme_grey_5);
  --selected-color-dark: var(--ioc_light_theme_grey_0);
  padding: var(--dot-padding-medium);
  margin: var(--dots-margin-medium);
  background-clip: padding-box;
  border-width: 3px;
  border-radius: 50%;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  display: flex;
}

.IO-Components__withCarousel__base--2dZF6 {
  background-color: var(--dots-color);
  border-color: rgba(0, 0, 0, 0);
}

.IO-Components__withCarousel__base--theme-dark--1vkDd {
  background-color: var(--dots-color-dark);
  border-color: rgba(0, 0, 0, 0);
}

.IO-Components__withCarousel__base--size-small--3Eq8k {
  padding: var(--dot-padding-small);
  margin: 0 var(--dots-margin-small);
}

.IO-Components__withCarousel__base--size-medium--3NQvi {
  padding: var(--dot-padding-medium);
  margin: 0 var(--dots-margin-medium);
}

.IO-Components__withCarousel__base--size-large--3msMx {
  padding: var(--dot-padding-large);
  margin: 0 var(--dots-margin-large);
}

.IO-Components__withCarousel__selected--19Y-i {
  background-color: var(--selected-color);
  border-color: rgba(0, 0, 0, 0);
}

.IO-Components__withCarousel__selected--theme-dark--3wOqD {
  background-color: var(--selected-color-dark);
  border-color: rgba(0, 0, 0, 0);
}

.IO-Components__withCarousel__selected--size-small--Jpy5n {
  padding: var(--dot-padding-small);
  margin: 0 var(--dots-margin-small);
}

.IO-Components__withCarousel__selected--size-medium--3vXer {
  padding: var(--dot-padding-medium);
  margin: 0 var(--dots-margin-medium);
}

.IO-Components__withCarousel__selected--size-large--jNj5L {
  padding: var(--dot-padding-large);
  margin: 0 var(--dots-margin-large);
}

.IO-Components__withCarousel__dots--wC3W0 {
  align-content: center;
  justify-content: center;
  padding: 13px 0 0;
  display: flex;
}

.IO-Components__DetachedColumnHeadings__wrapper--I5POd {
  --padding: 5px;
  --col-resizer-color-light: var(--ioc_light_theme_grey_4);
  --col-resizer-color-dark: var(--ioc_dark_theme_grey_4);
  --col-resizer-color: var(--col-resizer-color-light);
  --col-resizer-hover-color-light: var(--ioc_light_theme_grey_6);
  --col-resizer-hover-color-dark: var(--ioc_dark_theme_grey_6);
  --col-resizer-hover-color: var(--col-resizer-hover-color-light);
  font-size: var(--ioc_font_size_-1);
  font-family: var(--ioc_font_family_sans_serif);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.IO-Components__Toast__base--2jo7G {
  font-family: var(--ioc_font_family_sans_serif);
  max-width: 700px;
  display: block;
}

.IO-Components__Toast__base--size-small--1e5M9 {
  max-width: 300px;
}

.IO-Components__Toast__base--size-medium--1AKtx {
  max-width: 500px;
}

.IO-Components__Toast__base--size-large--1iQV4 {
  max-width: 700px;
}

.IO-Components__Toast__base--theme-dark--1_pEq {
  -webkit-box-shadow: var(--ioc_dark_theme_shadow);
  box-shadow: var(--ioc_dark_theme_shadow);
}

.IO-Components__Toast__base--width-flex--3MDzW {
  max-width: none;
}

.IO-Components__Toast__toast--nknJQ {
  color: var(--ioc_dark_theme_grey_0);
  -webkit-box-shadow: var(--ioc_light_theme_shadow);
  box-shadow: var(--ioc_light_theme_shadow);
  border-radius: 5px;
  align-items: center;
  padding: 15px 20px 15px 15px;
  display: inline-flex;
}

.IO-Components__Toast__toast--danger--scUL9 {
  background: var(--ioc_severity_danger);
  color: var(--ioc_light_theme_grey_0);
}

.IO-Components__Toast__toast--warning--1srhy {
  background: var(--ioc_severity_warning);
}

.IO-Components__Toast__toast--info---cZrN {
  background: var(--ioc_severity_info);
  color: var(--ioc_light_theme_grey_0);
}

.IO-Components__Toast__toast--success--1gBhP {
  background: var(--ioc_severity_success);
}

.IO-Components__Toast__toast--neutral--2HtQS {
  background: var(--ioc_severity_neutral);
  color: var(--ioc_light_theme_grey_0);
}

.IO-Components__Toast__message--1R-V2 {
  flex: 1;
  margin: 0 15px;
  line-height: 1.2;
}

.IO-Components__Toast__close--1PYao {
  margin-left: 10px;
}

.IO-Components__Switch__variables--1YbOJ {
  --switch-active-color: var(--active-color, var(--ioc_blue));
  --switch-bg-color: var(--bg-color, var(--ioc_light_theme_grey_0));
  --switch-border-color: var(--border-color, var(--ioc_light_theme_grey_2));
  --switch-dark-bg-color: var(--dark-bg-color, var(--ioc_dark_theme_grey_1));
  --switch-dark-border-color: var(--dark-border-color, var(--ioc_dark_theme_grey_0));
  --switch-width: 50px;
  --switch-height: 30px;
  --switch-knob-size: 26px;
}

.IO-Components__Switch__base--1i5OK {
  display: flex;
}

.IO-Components__Switch__base--size-small--3GWTy {
  --switch-width: 41px;
  --switch-height: 25px;
  --switch-knob-size: 21px;
}

.IO-Components__Switch__base--size-large--1S1Cc {
  --switch-width: 66px;
  --switch-height: 40px;
  --switch-knob-size: 35px;
}

.IO-Components__Switch__base--theme-dark--3FoFQ {
  --switch-bg-color: var(--switch-dark-bg-color);
  --switch-border-color: var(--switch-dark-border-color);
}

.IO-Components__Switch__switch--e-Smd {
  width: var(--switch-width);
  height: var(--switch-height);
  background: var(--switch-bg-color);
  border: 1px solid var(--switch-border-color);
  border-radius: var(--switch-width);
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.IO-Components__Switch__switch--e-Smd:before {
  background: var(--switch-active-color);
  -o-transition: transform .25s ease-in-out;
  content: "";
  width: 100%;
  height: 100%;
  transition: -webkit-transform .25s ease-in-out, transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
  position: absolute;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.IO-Components__Switch__switch--e-Smd:after {
  width: var(--switch-knob-size);
  height: var(--switch-knob-size);
  margin-left: calc(-1 * var(--switch-knob-size) / 2);
  margin-top: calc(-1 * var(--switch-knob-size) / 2);
  -o-transition: transform .2s ease-in-out;
  content: "";
  background: #fff;
  border-radius: 50%;
  transition: -webkit-transform .2s ease-in-out, transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-35%);
  transform: translateX(-35%);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .26);
}

.IO-Components__Switch__switch--e-Smd:checked:before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.IO-Components__Switch__switch--e-Smd:checked:after {
  -webkit-transform: translateX(35%);
  transform: translateX(35%);
}

.IO-Components__Switch__switch--e-Smd[disabled] {
  opacity: .5;
  cursor: auto;
}

.IO-Components__Switch__label--R0jOC {
  align-items: center;
  display: inline-flex;
}

.IO-Components__Switch__label__content--2QI_H {
  padding-left: 10px;
}

.IO-Components__ImprobableLogo__logo--light--3JE4J g {
  fill: var(--ioc_dark_theme_grey_6);
}

.IO-Components__HeaderMenuDropdown__menu-dropdown--2iGkC {
  color: var(--ioc_light_theme_grey_6);
  z-index: 10;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  box-shadow: 0 3px 16px rgba(0, 0, 0, .1);
}

.IO-Components__HeaderMenu__menu-trigger--3AY25 {
  color: var(--ioc_dark_theme_grey_6);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  display: flex;
}

.IO-Components__HeaderMenu__menu-trigger--3AY25:hover {
  background-color: rgba(255, 255, 255, .1);
}

.IO-Components__HeaderMenu__menu--3PILJ {
  position: relative;
}

.IO-Components__HeaderMenu__menu-dropdown--3XaQb {
  color: var(--ioc_light_theme_grey_6);
  z-index: 10;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  box-shadow: 0 3px 16px rgba(0, 0, 0, .1);
}

.IO-Components__HeaderMenu__match-trigger-width--38u9v {
  right: 0;
}

.IO-Components__AppHeader__container--2moLX {
  --border-color: #383a3e;
  background: var(--ioc_dark_theme_grey_0);
  font-family: var(--ioc_font_family_sans_serif);
  justify-content: space-between;
  min-height: 54px;
  display: flex;
}

.IO-Components__AppHeader__app-name--EFXns {
  margin-left: 10px;
  display: inline-block;
}

.IO-Components__AppHeader__section--24WW5 {
  display: flex;
}

.IO-Components__AppHeader__user-image--3Vmbm {
  border-radius: 99999px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  overflow: hidden;
}

.IO-Components__AppHeader__user-image--3Vmbm img {
  width: 100%;
}

.IO-Components__AppHeader__user--3yCrm {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.IO-Components__AppHeader__user-name--sK4ZX {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  margin-left: 10px;
  overflow: hidden;
}

.IO-Components__AppHeader__logo--ro67C {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.IO-Components__AppHeader__logo--ro67C g {
  fill: var(--ioc_dark_theme_grey_6);
}

.IO-Components__AppHeader__link--3GRy4 {
  color: var(--ioc_dark_theme_grey_6);
  border-right: 1px solid var(--border-color);
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
}

.IO-Components__AppHeader__menus--4gBDp, .IO-Components__AppHeader__user-menu--2iVlK {
  display: flex;
}

.IO-Components__AppHeader__user-menu-trigger--3I1eN {
  border-left: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  width: 220px;
  padding: 5px 10px;
  display: flex;
}

.IO-Components__AppHeader__help-menu-trigger--UWzX6 {
  border-left: 1px solid var(--border-color);
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  padding: 5px 10px;
  display: flex;
}

.IO-Components__AppHeader__user-menu-link--1bOca {
  color: inherit;
  display: block;
}

.IO-Components__AppHeader__user-menu-link--1bOca:hover {
  text-decoration: underline;
}

.IO-Components__AppHeader__user-menu-link--1bOca:not(:last-child) {
  margin-bottom: 5px;
}

.IO-Components__AppHeader__help-link--2lcCA {
  color: inherit;
  padding: 20px;
  font-size: 13px;
  display: block;
}

.IO-Components__AppHeader__help-link--2lcCA:hover {
  text-decoration: underline;
}

.IO-Components__AppHeader__help-link--2lcCA:not(:last-child) {
  border-bottom: 1px solid var(--ioc_dark_theme_grey_6);
}

.IO-Components__AppHeader__menu-section--2y8tM {
  padding: 20px;
  font-size: 11px;
}

.IO-Components__AppHeader__menu-section--2y8tM:not(:last-child) {
  border-bottom: 1px solid var(--ioc_dark_theme_grey_6);
}

.IO-Components__AppHeader__user-org--3EVhY {
  font-size: 13px;
}

.IO-Components__AppHeader__user-org-link--kflSJ {
  color: inherit;
}

.IO-Components__AppHeader__user-org-link--kflSJ:hover {
  text-decoration: underline;
}

.IO-Components__AppHeader__user-email--36k81 {
  color: var(--ioc_light_theme_grey_5);
  text-overflow: ellipsis;
  overflow: hidden;
}

.IO-Components__AppHeader__sign-out--2aG9E {
  color: var(--ioc_red);
}

.IO-Components__AppHeader__sign-out--2aG9E:hover {
  text-decoration: underline;
}

.uFGFCq_sign-in-button {
  align-items: center;
  display: flex;
}

.uFGFCq_icon-wrapper {
  color: #fff;
  background-color: #333;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
}

.uFGFCq_label {
  color: #333;
  padding: 0 60px;
  font-family: Muli;
  font-size: 16px;
  display: flex;
}

.uFGFCq_button {
  background-color: #e3e3e3;
  border: none;
  padding: 0;
}

.uFGFCq_logout {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
}

.FR8C2a_sign-in-button {
  align-items: center;
  display: flex;
}

.FR8C2a_icon-wrapper {
  color: #fff;
  background-color: #333;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
}

.FR8C2a_label {
  color: #333;
  padding: 0 60px;
  font-family: Muli;
  font-size: 16px;
  display: flex;
}

.FR8C2a_button {
  background-color: #e3e3e3;
  border: none;
  padding: 0;
}

.FR8C2a_logout {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
}

.NBxQeG_link a {
  color: #fff;
  text-decoration-line: none;
}

.NBxQeG_link a:hover {
  border-bottom: 1px solid #fff;
  line-height: 1.8;
}

.pjsrvq_links {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 1em;
  display: flex;
}

.pjsrvq_link-item {
  margin: 0 .5em;
}

.pjsrvq_dropdown {
  background-color: var(--ioc_dark_theme_grey_0);
  height: 3em;
  display: flex;
}

._0ZPZsq_component {
  color: var(--ioc_light_theme_grey_6);
  align-items: center;
  margin-bottom: 1.5em;
  font-size: 1em;
  display: flex;
}

._0ZPZsq_component a {
  color: inherit;
  opacity: .5;
  text-decoration: none;
}

._0ZPZsq_component a:hover {
  opacity: 1;
  color: var(--ioc_blue);
}

._0ZPZsq_component ._0ZPZsq_active {
  border-bottom-color: var(--ioc_blue);
  color: var(--ioc_blue);
  opacity: 1;
}

._0ZPZsq_component a:not(:last-child) {
  margin-right: 35px;
}

._0ZPZsq_link {
  white-space: nowrap;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  padding: 4px 0;
}

.MozPXa_add-cluster-button {
  margin-left: 1em;
}

.Kc_d1W_cluster-modal {
  width: 700px;
}

.Kc_d1W_buttons-container {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.Kc_d1W_link-and-force-buttons {
  align-items: center;
  display: flex;
}

.Kc_d1W_force-tooltip {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  padding: 2px 32px 0 0;
  display: flex;
}

.Kc_d1W_force-button-contents {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.Kc_d1W_detail-item {
  align-items: center;
  padding: 8px;
  display: flex;
}

.Kc_d1W_detail-header {
  align-items: center;
  padding: 32px 8px 8px;
  display: flex;
}

.Kc_d1W_detail-item-title {
  vertical-align: middle;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
}

.Kc_d1W_detail-item-value {
  flex: 1;
  display: inline-block;
}

.Kc_d1W_error-container {
  max-width: 500px;
}

.Kc_d1W_error-message {
  padding-top: 20px;
}

.qqBR5a_cluster-details-modal {
  width: 750px;
  max-height: 800px;
  overflow-y: auto;
}

.qqBR5a_overflow-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.qqBR5a_copy-button {
  cursor: pointer;
  padding-right: 10px;
}

.NOQuxa_multiline-tooltip {
  max-width: 350px;
  padding: 10px;
}

.NOQuxa_actions {
  display: flex;
}

.NOQuxa_more-options-button {
  padding-left: 10px;
  padding-right: 10px;
}

.NOQuxa_name-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.NOQuxa_cell {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
}

.NOQuxa_danger {
  color: var(--ioc_severity_danger);
}

.NOQuxa_icon-text-center {
  align-items: center;
  display: flex;
}

.NOQuxa_icon-text-center span {
  padding-left: 5px;
}

.NOQuxa_cluster-details-modal {
  width: 750px;
  max-height: 800px;
  overflow-y: auto;
}

.NOQuxa_overflow-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.NOQuxa_copy-button {
  cursor: pointer;
  padding-right: 10px;
}

.NOQuxa_table-title {
  font-weight: bold;
}

.dOQo1G_option-title {
  font-weight: var(--ioc_font_weight_bold);
  font-size: var(--ioc_font_size_0);
  margin-bottom: 10px;
  display: flex;
}

.dOQo1G_option-item {
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.dOQo1G_option-item-title {
  vertical-align: middle;
  flex: 1;
  padding-right: 10px;
  display: inline-block;
}

.dOQo1G_editor {
  border: 1px solid var(--ioc_light_theme_grey_3);
  background: #fff;
  border-radius: 3px;
  font-family: Fira code, Fira Mono, monospace;
  font-size: 13px;
}

.dOQo1G_editor textarea {
  outline: 0;
}

.dOQo1G_editor-focused {
  box-shadow: var(--ioc_focus_box_shadow);
  outline: 0;
}

.dOQo1G_option-section {
  border-left: 3px solid var(--ioc_light_theme_grey_5);
  margin-left: 10px;
  padding-left: 10px;
}

.CY3BZq_actions {
  display: flex;
}

.CY3BZq_table {
  width: 100%;
  height: 300px;
  overflow: auto;
}

.CY3BZq_cell {
  padding: 3px 5px;
  overflow: auto;
}

.CY3BZq_cell-timestamp {
  overflow: hidden;
}

.CY3BZq_status-info {
  vertical-align: -1px;
  padding-left: 5px;
  display: inline-block;
}

.CY3BZq_multiline-tooltip {
  max-width: 350px;
  padding: 10px;
}

.FtQD0G_detail-item {
  margin-bottom: 15px;
}

.FtQD0G_detail-item-title {
  font-size: var(--ioc_font_size_0);
  font-weight: var(--ioc_font_weight_bold);
  padding-bottom: 8px;
  display: inline-block;
}

.FtQD0G_inline {
  padding-left: 4px;
  display: inline-block;
}

.FtQD0G_max-number-configs-input {
  width: 48px;
  margin-left: 8px;
  display: inline-block;
}

.FtQD0G_max-number-configs-input div {
  width: inherit;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"]::selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: rgba(255, 255, 255, .5);
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.DZrGhG_detail-item {
  margin-bottom: 15px;
}

.DZrGhG_detail-item-title {
  font-size: var(--ioc_font_size_0);
  font-weight: var(--ioc_font_weight_bold);
  align-items: center;
  padding-bottom: 8px;
  display: flex;
}

.DZrGhG_detail-item-loader {
  padding-left: 4px;
}

.DZrGhG_detail-item-template-name {
  flex: 2;
  width: calc(100% - 160px);
  display: inline-block;
}

.DZrGhG_detail-item-template-version {
  width: 128px;
  margin-left: 32px;
  display: inline-block;
}

.DZrGhG_detail-item-description {
  padding-left: 10px;
  font-size: 13px;
}

.DZrGhG_loading-item {
  align-items: center;
  display: flex;
}

.DZrGhG_detail-loader {
  display: inline-block;
}

.DZrGhG_detail-loading-text {
  padding-left: 10px;
  font-size: 13px;
  display: inline-block;
}

.DZrGhG_max-number-configs-title {
  font-weight: var(--ioc_font_weight_bold);
  margin-left: 32px;
}

.DZrGhG_max-number-configs-input {
  width: 48px;
  margin-left: 8px;
  display: inline-block;
}

.DZrGhG_max-number-configs-input div {
  width: inherit;
}

.mXWn1q_deploy {
  width: 700px;
  max-height: 80vh;
  margin-bottom: 32px;
  overflow-y: scroll;
}

.mXWn1q_detail-item {
  margin-bottom: 15px;
}

.mXWn1q_detail-item-title {
  font-size: var(--ioc_font_size_0);
  font-weight: var(--ioc_font_weight_bold);
  align-items: center;
  padding-bottom: 8px;
  display: flex;
}

.mXWn1q_detail-item-loader {
  padding-left: 4px;
}

.mXWn1q_deploy-radios {
  align-items: center;
  display: flex;
}

.DD6sMG_icon-text-center {
  align-items: center;
  display: flex;
}

.DD6sMG_icon-text-center span {
  padding-left: 5px;
}

.E1nfTG_icon-text-center {
  align-items: center;
  display: flex;
}

.E1nfTG_icon-text-center span {
  padding-left: 5px;
}

.YwNmrq_error-container {
  max-width: 500px;
}

.YwNmrq_error-message {
  padding-top: 20px;
}

.k8OaXq_deploy {
  width: 450px;
  margin-bottom: 32px;
}

.k8OaXq_detail-item {
  margin-bottom: 15px;
}

.k8OaXq_detail-item-title {
  font-size: var(--ioc_font_size_0);
  font-weight: var(--ioc_font_weight_bold);
  padding-bottom: 8px;
}

.k8OaXq_detail-item-value {
  font-size: var(--ioc_font_size_0);
  padding-bottom: 8px;
  padding-left: 8px;
}

.k8OaXq_error-container {
  max-width: 500px;
}

.k8OaXq_error-message {
  padding-top: 20px;
}

.jxNd0W_actions {
  display: flex;
}

.jxNd0W_table-tree-views {
  background: #fff;
  border-bottom: none;
}

.jxNd0W_no-allocations {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 10px;
}

.jxNd0W_more-options-button {
  padding-left: 10px;
  padding-right: 10px;
}

.jxNd0W_force-tooltip {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  display: flex;
}

.jxNd0W_icon-text-center {
  align-items: center;
  display: flex;
}

.jxNd0W_icon-text-center span {
  padding-left: 5px;
}

.jxNd0W_cell {
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
}

.jxNd0W_template-value-modal {
  width: 1100px;
  max-height: 800px;
  overflow-y: auto;
}

.jxNd0W_template-value-link {
  cursor: pointer;
  padding-left: 5px;
  font-weight: 600;
  text-decoration: underline;
}

.jxNd0W_overflow-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.jxNd0W_copy-button {
  cursor: pointer;
  padding-right: 10px;
}

.jxNd0W_status-dot {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

.jxNd0W_status-info {
  vertical-align: -1px;
  padding-left: 5px;
  display: inline-block;
}

.jxNd0W_multiline-tooltip {
  max-width: 350px;
  padding: 10px;
}

.jxNd0W_danger {
  color: var(--ioc_severity_danger);
}

.jxNd0W_table-title, .jxNd0W_table-header {
  font-weight: bold;
}

.jxNd0W_metadata-tag {
  padding: 3px;
  display: inline-block;
}

.fcLxFa_table-header {
  white-space: break-spaces;
}

._1d_TUq_table-container {
  max-width: 1000px;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

._1d_TUq_cell {
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
}

._1d_TUq_icon-text-center {
  align-items: center;
  display: flex;
}

._1d_TUq_icon-text-center span {
  padding-left: 5px;
}

._1d_TUq_trash-button {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

._1d_TUq_delete-gameserver-tooltip {
  white-space: normal;
  max-width: 380px;
  padding: 20px;
}

._1d_TUq_delete-gameserver-tooltip-button {
  margin-top: 10px;
}

._1d_TUq_delete-warning {
  padding: 15px 0;
  display: block;
}

.Y_YuRG_table-title {
  font-weight: bold;
}

.Y_YuRG_cell {
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
}

.Y_YuRG_actions {
  display: flex;
}

.Y_YuRG_more-options-button {
  padding-left: 10px;
  padding-right: 10px;
}

.Y_YuRG_icon-text-center {
  align-items: center;
  display: flex;
}

.Y_YuRG_icon-text-center span {
  padding-left: 5px;
}

.iTy17q_background {
  background-image: url("background.f5dfba6b.png");
  background-size: cover;
}

.iTy17q_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.iTy17q_box {
  background-color: #f5f5f5;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  font-family: Muli;
  display: flex;
}

.iTy17q_title {
  font-family: Muli;
  font-size: 45px;
}

.iTy17q_auth-button {
  margin-top: 30px;
  margin-bottom: 20px;
}

.iTy17q_sign-up-link {
  color: #587df3;
}

.WzJpiW_notifications-wrapper {
  z-index: 1001;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 64px;
  right: 10px;
}

.WzJpiW_notifications-wrapper > * {
  margin-bottom: 10px;
}

.dKSbzW_floating-tooltip-root {
  z-index: 10;
  position: relative;
}

.dKSbzW_floating-tooltip-modal-root {
  z-index: 1010;
  position: relative;
}

body {
  background-color: var(--ioc_light_theme_grey_2);
  margin: 0;
  font-family: Soleil, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

/*# sourceMappingURL=index.3e21ed0d.css.map */
